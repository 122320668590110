<template>
  <div>
    <div class="form-row mb-2">
      <div class="col-12 col-w-full">
        <WhiteLabel>
          <h4 class="font-weight-light blue">Informações do Representante</h4>
        </WhiteLabel>
      </div>
      <div
        class="form-group col-12 col-w-full col-lg-7 mb-4"
        :class="{ 'has-error': reprensentativeNameIsInvalid }"
      >
        <label for="inputName">Qual é o nome do Representante?</label>
        <input
          type="text"
          class="form-control"
          @input="$parent.$v.customer.representative.name.$touch()"
          v-model="customer.representative.name"
          placeholder="Digite aqui o nome do representante"
          maxlength="120"
        />
        <small
          class="text-danger"
          v-if="!$parent.$v.customer.representative.name.propName"
          >Somente letras</small
        >
      </div>
      <div
        class="form-group col-12 col-w-full col-lg-5 mb-4"
        :class="{ 'has-error': reprensentativeTaxDocumentIsInvalid }"
      >
        <label for="inputCPF">E o CPF dele?</label>
        <input
          type="text"
          class="form-control text-center"
          @input="$parent.$v.customer.representative.taxDocument.$touch()"
          v-model="customer.representative.taxDocument"
          placeholder="Digite aqui o CPF do representante"
          v-mask="'###.###.###-##'"
        />
        <small
          class="text-danger"
          v-if="!$parent.$v.customer.representative.taxDocument.cpf"
          >O campo CPF está invalido</small
        >
      </div>
    </div>
    <div class="form-row mb-4">
      <div class="form-group col-6 col-lg-4 col-xl-4 mb-4">
        <label>Gênero</label>
        <select
          class="form-control text-center"
          name="gender"
          v-model="customer.representative.gender"
        >
          <option value>Selecione</option>
          <option value="F">Feminino</option>
          <option value="M">Masculino</option>
        </select>
      </div>
      <div class="form-group col-6 col-lg-3 col-xl-3 mb-4">
        <label for="inputBirthDate">Data Nascimento</label>
        <datepicker
          v-model="customer.representative.birthDate"
          :calendar-button="false"
          calendar-button-icon="fa fa-calendar"
          input-class="form-control text-center read"
          language="pt-br"
          format="dd/MM/yyyy"
          placeholder="00/00/0000"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mixins } from './mixins.js';
import Datepicker from 'vuejs-datepicker';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppRepresentative',
  mixins: [mixins],
  computed: {
    reprensentativeNameIsInvalid() {
      return (
        this.$parent.$v.customer.representative.name.$invalid &&
        this.$parent.$v.customer.representative.name.$dirty
      );
    },
    reprensentativeTaxDocumentIsInvalid() {
      return (
        this.$parent.$v.customer.representative.taxDocument.$invalid &&
        this.$parent.$v.customer.representative.taxDocument.$dirty
      );
    },
    reprensentativeGenderIsInvalid() {
      return (
        this.$parent.$v.customer.representative.gender.$invalid &&
        this.$parent.$v.customer.representative.gender.$dirty
      );
    },
  },
  components: {
    Datepicker,
    WhiteLabel,
  },
  props: {
    customer: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>
