<template>
  <div class="form-row">
    <div class="col-12 col-w-full">
      <WhiteLabel>
        <h4 class="font-weight-light blue">Informações de Contato</h4>
      </WhiteLabel>
    </div>
    <div
      class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4"
      :class="{ 'has-error': emailIsInvalid }"
    >
      <label for="inputEmail">E-mail</label>
      <input
        type="email"
        class="form-control"
        v-model="customer.email"
        @input="$parent.$v.customer.email.$touch()"
        placeholder="Digite aqui seu email"
      />
      <small class="text-danger" v-if="emailIsInvalid">E-mail inválido.</small>
    </div>
    <div class="form-group col-12 col-w-full col-md-6 col-lg-3 mb-4">
      <label for="inputPhone">Telefone</label>
      <input
        type="text"
        class="form-control text-center"
        v-model="customer.phone"
        v-mask="['(##) ####-####', '(##) #####-####']"
        placeholder="(00) 0 0000-0000"
      />
    </div>
    <div class="form-group col-12 col-w-full col-md-6 col-lg-3 mb-4">
      <label for="inputPhone">Celular</label>
      <input
        type="text"
        class="form-control text-center"
        v-model="customer.mobile"
        v-mask="['(##) ####-####', '(##) #####-####']"
        placeholder="(00) 0 0000-0000"
      />
    </div>
    <div class="col-12 col-w-full">
      <WhiteLabel>
        <h4 class="font-weight-light blue">
          Não enviar alertas e lembretes sobre vencimento de faturas e boletos
        </h4>
      </WhiteLabel>

      <WhiteLabel class="d-flex col-6">
        <label class="radio-selector d-flex">
          <input
            type="checkbox"
            v-model="customer.emailNotificationPaused"
            @input="changeEmail(customer.emailNotificationPaused)"
            :disabled="!enableEmail"
          />
          <div class="checkbox-badge mt-1 mr-2" :disabled="!enableEmail"></div>
          <h4 class="mb-0 d-flex">E-mail</h4>
        </label>
        <label class="radio-selector d-flex mx-auto">
          <input
            type="checkbox"
            v-model="customer.mobileNotificationPaused"
            @input="changeSms(customer.mobileNotificationPaused)"
            :disabled="!enableSms"
          />
          <div class="checkbox-badge mt-1 mr-2"></div>
          <h4 class="mb-0">Sms</h4>
        </label>
      </WhiteLabel>
    </div>
  </div>
</template>
<script>
import { mixins } from './mixins.js';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppContactInformation',
  components: {
    WhiteLabel,
  },
  mixins: [mixins],
  computed: {
    emailIsInvalid() {
      return (
        this.$parent.$v.customer.email.$invalid &&
        this.$parent.$v.customer.email.$dirty
      );
    },
    enableEmail() {
      if (this.customer.email != null) {
        return this.customer.email.length > 0;
      }
    },
    enableSms() {
      if (this.customer.mobile != null) {
        return this.customer.mobile.length > 0;
      }
    },
  },
  watch: {
    'customer.email': function(email) {
      if (email == null || email == '') {
        this.customer.emailNotificationPaused = false;
      }
    },
    'customer.mobile': function(sms) {
      if (sms == null || sms == '') {
        this.customer.mobileNotificationPaused = false;
      }
    },
  },
  methods: {
    changeEmail: function(value) {
      this.$emit('inputEmail', value);
    },
    changeSms: function(value) {
      this.$emit('inputSms', value);
    },
  },
};
</script>
