<template>
  <div>
    <SplitterForm
      v-if="mountIsDone"
      :isEdit="isEdit"
      :splitterEdit="splitterEdit"
    />
  </div>
</template>
<script>
import SplitterForm from './SplitterForm';
import SplitterApi from '@/services/v2/SplitterApi';

export default {
  name: 'AppSplitterRegistration',

  components: {
    SplitterForm,
  },

  data: () => ({
    isEdit: false,
    splitterEdit: null,
    mountIsDone: false,
  }),

  async beforeMount() {
    this.$bus.$emit('spinner-run');
    await this.findSplitter();
    this.$bus.$emit('spinner-stop');
  },

  methods: {
    async findSplitter() {
      this.isEdit =
        this.$route.params.splitterId !== null &&
        this.$route.params.splitterId !== undefined;
      if (
        this.$route.params.splitterId <= 0 ||
        this.$route.params.splitterId === null ||
        this.$route.params.splitterId === undefined
      ) {
        this.$router.push({ name: 'new-splitter' });
        this.mountIsDone = true;
      } else {
        const api = new SplitterApi();
        const splitter = await api.get(this.$route.params.splitterId);
        this.splitterEdit = splitter;
        this.mountIsDone = true;
      }
    },
  },
};
</script>
