import CustomApiError from './CustomError';

export default class SplitterError extends CustomApiError {
  toDictionary() {
    return {
      DUPLICATE_TAX_DOCUMENT:
        'Já existe um splitter vinculado com esse CPF ou CNPJ.',
      TRANSFER_DAY_NOT_AVAILABLE: 'Dias para repasse não disponível',
      TRANSFER_DAYS_EXCEEDED_MINIMUM_VALUE:
        'Verifique a quantidade de dias de repasse escolhido.',
      EMAIL_ALREADY_USED: 'Email já utilizado',
    };
  }
}
