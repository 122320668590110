import axios from 'axios';
import { UnitMeasureFilter } from '@/models/UnitMeasure';

class CreditProtection {
  async getQueryTypes() {
    const request = await axios.get(`${process.env.VUE_APP_BUSINESS_API_URL}/credit-protection/types`);
    return request.data;
  }

  async getDocumentQuery(document, queryTypeId) {
    var viewModel = { taxDocument: document, typeId: queryTypeId };
    const request = await axios.post(`${process.env.VUE_APP_BUSINESS_API_URL}/credit-protection/search`, viewModel);

    return request.data;
  }

  async getQueryHistory(filter) {
    const request = await axios.get(`${process.env.VUE_APP_BUSINESS_API_URL}/credit-protection/history?protocol=${filter.protocol}&taxDocument=${filter.taxDocument}&index=${filter.offset.index}`);
    return request.data;
  }
}

class UnitMeasures {
  async search(unitFilter) {
    const request = await axios.post(`${process.env.VUE_APP_BUSINESS_API_URL}/units-measure/all`, { ...unitFilter });
    return request.data;
  }

  async all() {
    return this.search(new UnitMeasureFilter());
  }
}

class Customer {
  async search(customerFilter) {
    var url = `${process.env.VUE_APP_BUSINESS_API_URL}/customers?fullName=${customerFilter.fullName}&email=${customerFilter.email}&taxDocument=${customerFilter.taxDocument}&offset.index=${customerFilter.offset.index}&${customerFilter.offset.limit}`;
    const request = await axios.get(url);
    return request.data;
  }

  async find(customerId) {
    var url = `${process.env.VUE_APP_BUSINESS_API_URL}/customers/${customerId}`;
    const request = await axios.get(url);
    return request.data;
  }

  async remove(customerId) {
    var url = `${process.env.VUE_APP_BUSINESS_API_URL}/customers/${customerId}`;
    const request = await axios.delete(url);
    return request.data;
  }

  async save(customer) {
    var url = `${process.env.VUE_APP_BUSINESS_API_URL}/customers`;
    const response = await axios.post(url, customer);
    return response.data;
  }

  async update(customer) {
    const url = `${process.env.VUE_APP_BUSINESS_API_URL}/customers/${customer.id}`;
    const response = await axios.put(url, customer);
    return response.data;
  }

  async inactive(customerId) {
    var url = `${process.env.VUE_APP_BUSINESS_API_URL}/customers/${customerId}/deactivate`;
    const response = await axios.post(url);
    return response.data;
  }

  async active(customerId) {
    var url = `${process.env.VUE_APP_BUSINESS_API_URL}/customers/${customerId}/activate`;
    const response = await axios.post(url);
    return response.data;
  }

  async pauseEmail(customerId) {
    var url = `${process.env.VUE_APP_BUSINESS_API_URL}/customers/${customerId}/pause-email-notification`;
    const response = await axios.post(url);
    return response.data;
  }

  async pauseSms(customerId) {
    var url = `${process.env.VUE_APP_BUSINESS_API_URL}/customers/${customerId}/pause-sms-notification`;
    const response = await axios.post(url);
    return response.data;
  }
}

class LinkPayment {
  async search(filter) {
    var query = [];

    if (filter.fullName) {
      query.push(`name=${filter.fullName}&`);
    }

    if (filter.email) {
      query.push(`email=${filter.email}&`);
    }

    if (filter.document) {
      query.push(`document=${filter.document}&`);
    }

    if (filter.status.length > 0) {
      query.push(`status[0]=${filter.status[0]}&`);
    }

    if (filter.index) {
      query.push(`index=${filter.index}&`);
    }

    if (filter.limit) {
      query.push(`length=${filter.limit}`);
    }

    query = query.join('');

    let url = `${process.env.VUE_APP_BILLING_API_URL}?${query}`;
    const response = await axios.get(url);

    return response.data;
  }

  async find(linkPaymentId) {
    let url = `${process.env.VUE_APP_BILLING_API_URL}/${linkPaymentId}`;
    const response = await axios.get(url);

    return response.data;
  }

  async cancel(linkPaymentId) {
    let url = `${process.env.VUE_APP_BILLING_API_URL}/cancel/${linkPaymentId}`;
    const response = await axios.delete(url);

    return response.data;
  }

  async save(linkPayment) {
    let url = `${process.env.VUE_APP_BILLING_API_URL}`;
    const response = await axios.post(url, linkPayment);

    return response;
  }
}

export default class BusinessApi {
  customers = new Customer()
  creditProtection = new CreditProtection()
  unitMeasures = new UnitMeasures()
  linkPayments = new LinkPayment()
}
