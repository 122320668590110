import axios from 'axios';

class BankApi {
  async getAll() {
    const url = `${process.env.VUE_APP_ACCOUNT_API_URL}/banks`;
    const result = await axios.get(url);

    return result.data;
  }
}

export default BankApi;
