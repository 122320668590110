<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-w-full col-lg-10 col-xl-8">
        <div class="card">
          <div class="d-flex">
            <h3 class="font-weight-light pull-left ml-3">Splitter</h3>
            <WhiteLabel class="ml-auto mt-1 pull-right mr-4">
              <router-link :to="{ name: 'splitter' }">
                <i class="icon-arrow-left-circle"></i> Voltar
              </router-link>
            </WhiteLabel>
          </div>
          <hr />
          <div class="form-row col-12" v-if="splitter.id">
            <div class="col-12 col-w-full">
              <WhiteLabel>
                <h4 class="font-weight-light blue">Informações do splitter</h4>
              </WhiteLabel>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterName">Nome</label>
              <p>{{ splitter.name }}</p>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterMobile">Celular</label>
              <p>{{ splitter.mobile }}</p>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterEmail">Email</label>
              <p>{{ splitter.email }}</p>
            </div>
            <div class="form-group col-12 col-w-full col-md-6 col-lg-3 mb-4">
              <label for="splitterTransferDays"
                >Dias para repasse do splitter</label
              >
              <p>{{ splitter.transferDays }}</p>
            </div>
            <div class="form-group col-12 col-w-full col-md-6 col-lg-3 mb-4">
              <label for="splitterAnticipatedTransfer"
                >Repasse antecipado</label
              >
              {{ splitter.anticipatedTransfer ? "Ativo" : "Inativo" }}
              <div class="checkbox-badge mt-1 mr-2"></div>
            </div>
            <div class="col-12 col-w-full">
              <WhiteLabel>
                <h4 class="font-weight-light blue">Endereço</h4>
              </WhiteLabel>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterZipCode">CEP</label>
              <p>{{ splitter.address.zipCode }}</p>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterDistrict">Estado</label>
              <p>{{ splitter.address.state }}</p>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterDistrict">Cidade</label>
              <p>{{ splitter.address.city }}</p>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterAddressLine1">Endereço</label>
              <p>{{ splitter.address.line1 }}</p>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterStreetNumber">Número</label>
              <p>{{ splitter.address.streetNumber }}</p>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterAddressLine2">Complemento</label>
              <p>{{ splitter.address.line2 }}</p>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterDistrict">Bairro</label>
              <p>{{ splitter.address.district }}</p>
            </div>
            <div class="col-12 col-w-full">
              <WhiteLabel>
                <h4 class="font-weight-light blue">Conta bancária</h4>
              </WhiteLabel>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterBankAccountHolderName">Nome do títular</label>
              <p>{{ splitter.bankAccount.holderName }}</p>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterBankAccountTaxDocument">CPF/CNPJ</label>
              <p>{{ splitter.bankAccount.taxDocument }}</p>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterBankAccountBankName">Nome do banco</label>
              <p>{{ bankName }}</p>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterBankAccountBranchNumber">Agência</label>
              <p>{{ splitter.bankAccount.bankBranchNumber }}</p>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterBankAccountNumber">Conta</label>
              <p>{{ splitter.bankAccount.accountNumber }}</p>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterBankAccountVariation">Variação</label>
              <p>{{ splitter.bankAccount.variation }}</p>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterBankAccountType">TIpo de conta</label>
              <p>{{ splitter.bankAccount.type }}</p>
            </div>
            <div class="col-12 col-w-full">
              <WhiteLabel>
                <h4 class="font-weight-light blue">Saldo</h4>
              </WhiteLabel>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterBankAccountVariation"
                >Atual (sem aplicação de taxas)*</label
              >
              <p>
                {{
                  splitterBalance.currentBalance
                    | currency("R$ ", 2, {
                      decimalSeparator: ",",
                      thousandsSeparator: ".",
                    })
                }}
              </p>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterBankAccountType">Futuro</label>
              <p>
                {{
                  splitterBalance.futureBalance
                    | currency("R$ ", 2, {
                      decimalSeparator: ",",
                      thousandsSeparator: ".",
                    })
                }}
              </p>
            </div>
            <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
              <label for="splitterBankAccountVariation">Bloqueado</label>
              <p v-if="splitterBalance.balanceBlocked">Sim</p>
              <p v-else>Não</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import paggcerto from 'paggcerto-lightbox/src/js';
import AddressCustomer from './AddressCustomer';
import ContactInformation from './ContactInformation';
import { Splitter } from '@/models/Customer';
import { SplitterBalance } from '@/models/Splitter';
import SplitterApi from '@/services/v2/SplitterApi';
import BanksApi from '@/services/v2/BanksApi';
import { PermissionList } from '@/models/Permission';
import WhiteLabel from '@/components/shared/WhiteLabel';
import Checkbox from '@/components/shared/Checkbox';

export default {
  name: 'AppSplitterInformation',
  data() {
    return {
      permissionList: new PermissionList(),
      splitterBalance: new SplitterBalance(),
      splitter: {
        id: null,
      },
      bankName: '',
    };
  },
  async beforeMount() {
    const api = new SplitterApi();
    const splitter = await api.get(this.$route.params.splitterId);
    this.splitter = splitter;
    if (splitter.id) {
      this.getBankName(this.splitter.bankAccount.bankNumber);
    }

    const result = await api.getBalance({ ids: [this.splitter.id] });

    if (result.balanceList[0]) {
      this.splitterBalance = result.balanceList[0];
    }
  },
  async mounted() {
    this.$bus.$emit('spinner-run');
    if (this.whoAmI.user.role !== null) {
      await this.getScopes();
    }
    this.$bus.$emit('spinner-stop');
  },
  components: {
    WhiteLabel,
  },
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
      token: 'account/token',
    }),

    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    isAccountActive() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.active;
    },
  },
  methods: {
    ...mapActions({
      askWhoAmI: 'account/askWhoAmI',
    }),
    async getScopes() {
      this.permissionList.fromScopes(this.whoAmI.user.role.scopes);
    },
    async getBankName(id) {
      const api = new BanksApi();
      api.getAll().then((result) => {
        const { banks } = result;
        if (!banks.length) {
          return id;
        }

        const foundBank = banks.find((bank) => bank.number === id);
        this.bankName = foundBank.name ? foundBank.name : id;
      });
    },
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    customerEdit: {
      type: Object,
      default: null,
    },
  },
};
</script>
