<template>
  <div>
    <div class="form-row mb-2">
      <div class="col-12 col-w-full">
        <WhiteLabel>
          <h4 class="font-weight-light blue">Informações do Representante</h4>
        </WhiteLabel>
      </div>
      <div class="form-group col-12 col-w-full col-lg-7 mb-4">
        <label for="inputName">Nome do representante</label>
        <p>{{ customer.representative.name }}</p>
      </div>
      <div class="form-group col-12 col-w-full col-lg-5 mb-4">
        <label for="inputCPF">CPF</label>
        <p>{{ customer.representative.taxDocument }}</p>
      </div>
    </div>
    <div class="form-row mb-4">
      <div class="form-group col-6 col-lg-4 col-xl-4 mb-4">
        <label>Gênero</label>
        <p>
          {{
            customer.representative.gender === 'F' ? 'Feminino' : 'Masculino'
          }}
        </p>
      </div>
      <div class="form-group col-6 col-lg-3 col-xl-3 mb-4">
        <label for="inputBirthDate">Data Nascimento</label>
        <p>{{ customer.representative.birthDate | moment('DD/MM/YYYY') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mixins } from './mixins.js';
import Datepicker from 'vuejs-datepicker';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppRepresentative',
  components: {
    WhiteLabel,
  },
  mounted() {},
  mixins: [mixins],
  props: {
    customer: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>
