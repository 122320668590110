<template>
  <div class="form-row">
    <div class="col-12 col-w-full">
      <WhiteLabel>
        <h4 class="font-weight-light blue">Informações de Contato</h4>
      </WhiteLabel>
    </div>
    <div class="form-group col-12 col-w-full col-lg-6 col-xl-6 mb-4">
      <label for="inputEmail">E-mail</label>
      <p>{{ customer.email }}</p>
    </div>
    <div class="form-group col-12 col-w-full col-md-6 col-lg-3 mb-4">
      <label for="inputPhone">Telefone</label>
      <p>{{ customer.phone }}</p>
    </div>
    <div class="form-group col-12 col-w-full col-md-6 col-lg-3 mb-4">
      <WhiteLabel>
        <label for="inputPhone">Celular</label>
      </WhiteLabel>
      <p>{{ customer.mobile }}</p>
    </div>
    <div class="col-12 col-w-full">
      <WhiteLabel>
        <h4 class="font-weight-light blue">
          Não enviar alertas e lembretes sobre vencimento de faturas e boletos
        </h4>
      </WhiteLabel>
      <div class="d-flex col-6">
        <label class="radio-selector d-flex">
          <input
            type="checkbox"
            v-model="customer.emailNotificationPaused"
            disabled="disabled"
          />
          <div class="checkbox-badge mt-1 mr-2"></div>
          <h4 class="mb-0 d-flex">Email</h4>
        </label>
        <label class="radio-selector d-flex mx-auto">
          <input
            type="checkbox"
            v-model="customer.mobileNotificationPaused"
            disabled="disabled"
          />
          <div class="checkbox-badge mt-1 mr-2"></div>
          <h4 class="mb-0">Sms</h4>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import { mixins } from './mixins.js';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppContactInformation',
  components: {
    WhiteLabel,
  },
  mixins: [mixins],
};
</script>
