import { withParams } from 'vuelidate/lib';
import { noAccents } from '@/functions/noAccents';

const twoPlusWords = withParams({ type: 'twoPlusWords' }, value => {
  var str = noAccents(value);
  if (!str) return true;
  var regex = new RegExp('^[a-zA-Z0-9_.-]+ [a-zA-Z0-9-].+$');
  return regex.test(str);
});

export { twoPlusWords };
