<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-w-full col-lg-10 col-xl-8">
        <div class="card mt-5">
          <div class="d-flex">
            <h3 class="font-weight-light pull-left ml-3">Splitter</h3>
            <WhiteLabel class="ml-auto mt-1 pull-right mr-4">
              <router-link :to="{ name: 'splitter' }">
                <i class="icon-arrow-left-circle"></i> Voltar
              </router-link>
            </WhiteLabel>
          </div>
          <hr />
          <form action="#" @submit.prevent="save()">
            <div class="card-content mb-5">
              <div class="form-row">
                <div class="col-sm-12">
                  <WhiteLabel>
                    <h4 class="font-weight-light blue">Dados pessoais</h4>
                  </WhiteLabel>
                </div>
                <div class="form-group col-12 col-w-full col-lg-7 mb-4">
                  <label for="inputNome">Nome *</label>
                  <input
                    type="text"
                    class="form-control"
                    @input="$v.splitter.name.$touch()"
                    v-model="splitter.name"
                    maxlength="120"
                    placeholder="Digite aqui o nome identificador do splitter"
                  />
                  <small
                    class="text-danger"
                    v-if="!$v.splitter.name.required && $v.splitter.name.$dirty"
                    >Campo obrigatório</small
                  >
                  <small
                    class="text-danger"
                    v-if="!$v.splitter.name.alphaNumeric"
                    >Permitido somente letras</small
                  >
                </div>
                <div class="form-group col-12 col-w-full col-lg-3 mb-4">
                  <label>Celular </label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Celular"
                    v-model="splitter.mobile"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                  />
                  <small class="text-danger" v-if="!$v.splitter.mobile.phone"
                    >O campo Celular está invalido</small
                  >
                </div>
                <div class="form-group col-12 col-w-full col-lg-6 mb-4">
                  <label>Email</label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Digite o Email"
                    v-model="splitter.email"
                    @input="$v.splitter.email.$touch()"
                  />
                  <small class="text-danger" v-if="!$v.splitter.email.onlyEmail"
                    >O campo Email está invalido</small
                  >
                </div>
                <div
                  v-if="!isEdit || $v.splitter.email.$dirty"
                  class="form-group col-12 col-w-full col-lg-6 mb-4"
                >
                  <label>Confirme seu Email</label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Digite o Email"
                    v-model="splitter.confirmEmail"
                  />
                  <small
                    class="text-danger"
                    v-if="!$v.splitter.confirmEmail.onlyEmail"
                    >O campo Email está invalido</small
                  >
                </div>
              </div>
              <div class="form-row">
                <div class="col-sm-12">
                  <WhiteLabel>
                    <h4 class="font-weight-light blue">Plano de recebimento</h4>
                  </WhiteLabel>
                </div>
                <div class="form-group col-12 col-w-full col-lg-4 mb-4">
                  <label>Dias de repasse *</label>
                  <select
                    class="form-control text-center"
                    v-model="splitter.transferDays"
                    @change="setTransferPlan()"
                  >
                    <option :value="2">2 dias</option>
                    <option :value="32">32 dias</option>
                  </select>
                </div>
                <div
                  class="form-group col-12 col-w-full col-lg-4 offset-lg-1 mb-4"
                >
                  <label class="radio-selector mb-2 w-100 d-flex">
                    <WhiteLabel>
                      <input
                        type="checkbox"
                        v-model="splitter.anticipatedTransfer"
                        :disabled="splitter.transferDays < 32"
                      />
                      <div
                        class="checkbox-badge mr-3"
                        :disabled="splitter.transferDays < 32"
                      />
                    </WhiteLabel>
                    <span
                      :class="`mb-0${
                        splitter.transferDays < 32 ? 'font-weight-bold' : ''
                      }`"
                    >
                      {{
                        splitter.anticipatedTransfer
                          ? 'Antecipado'
                          : 'Não antecipado'
                      }}
                    </span>
                  </label>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-12">
                  <WhiteLabel>
                    <h4 class="font-weight-light blue">Endereço</h4>
                  </WhiteLabel>
                </div>
                <div class="form-group col-12 col-w-full col-lg-6 mb-4">
                  <label for="inputZipCode">CEP *</label>
                  <input
                    type="text"
                    class="form-control"
                    @change="searchZipCode(splitter.address.zipCode)"
                    v-mask="'#####-###'"
                    v-model="splitter.address.zipCode"
                    placeholder="Digite aqui seu CEP"
                  />
                </div>
              </div>
              <div class="form-row mb-4">
                <div class="form-group col-12 col-w-full col-lg-6 mb-4">
                  <label for="inputAddressLine1"
                    >Avenida/Rua/Logradouro *</label
                  >
                  <input
                    type="text"
                    maxlength="150"
                    v-model="splitter.address.line1"
                    class="form-control"
                    placeholder="Digite aqui seu logradouro"
                    @input="$v.splitter.address.line1.$touch()"
                  />
                  <small
                    class="text-danger"
                    v-if="(addressLine1IsInvalid = false)"
                    >O campo está inválido.</small
                  >
                </div>
                <div class="form-group col-6 col-lg-2 mb-4">
                  <label for="inputAddressNumber">Número *</label>
                  <input
                    type="text"
                    maxlength="10"
                    v-model="splitter.address.streetNumber"
                    placeholder="Nº"
                    @input="$v.splitter.address.streetNumber.$touch()"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-6 col-lg-4 mb-4">
                  <label for="inputAddressLine2">Complemento</label>
                  <input
                    type="text"
                    v-model="splitter.address.line2"
                    maxlength="80"
                    placeholder="Apartamento, bloco..."
                    class="form-control"
                    @input="$v.splitter.address.line2.$touch()"
                  />
                </div>
                <div class="form-group col-12 col-w-full col-lg-6 mb-4">
                  <label for="inputNeighborhood">Bairro *</label>
                  <input
                    type="text"
                    class="form-control"
                    maxlength="50"
                    placeholder="Digite aqui o seu bairro"
                    v-model="splitter.address.district"
                    :disabled="isDisabledDistrict"
                    @input="$v.splitter.address.district.$touch()"
                  />
                </div>
                <div class="form-group col-4 col-lg-2 mb-4">
                  <label for="inputState">Estado(UF) *</label>
                  <select
                    class="form-control text-center"
                    name="state"
                    @change="searchCities(splitter.address.state)"
                    v-model="splitter.address.state"
                  >
                    <option value>Selecione</option>
                    <option
                      v-for="state in allStates"
                      :key="state.id"
                      :value="state.sigla"
                    >
                      {{ state.sigla }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-8 col-lg-4">
                  <label for="inputCity">Cidade *</label>
                  <select
                    class="form-control"
                    name="cityCode"
                    v-model="splitter.address.cityCode"
                  >
                    <option value>Selecione</option>
                    <option
                      v-for="city in allCities"
                      :key="city.id"
                      :value="city.id"
                      :selected="
                        splitter.address.cityCode === city.id ? 'selected' : ''
                      "
                    >
                      {{ city.nome }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="col-sm-12">
                  <WhiteLabel>
                    <h4 class="font-weight-light blue">
                      Conta bancária do Splitter
                    </h4>
                  </WhiteLabel>
                </div>
                <div class="form-group col-12 col-w-full col-lg-7 mb-4">
                  <label for="inputHolderName"
                    >Nome do títular da conta *</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    @input="$v.splitter.bankAccount.holderName.$touch()"
                    v-model="splitter.bankAccount.holderName"
                    maxlength="120"
                    placeholder="Digite aqui o nome do titular da conta bancária"
                  />
                  <small
                    class="text-danger"
                    v-if="
                      !$v.splitter.bankAccount.holderName.required &&
                      $v.splitter.bankAccount.holderName.$dirty
                    "
                    >Campo obrigatório</small
                  >
                  <small
                    class="text-danger"
                    v-if="!$v.splitter.bankAccount.holderName.propName"
                    >Permitido somente letras</small
                  >
                </div>
                <div class="form-group col-12 col-w-full col-lg-5 mb-4">
                  <label for="inputCPFCNPJ">CPF/CNPJ *</label>

                  <input
                    type="text"
                    class="form-control"
                    required
                    id="taxDocument"
                    v-model="splitter.bankAccount.taxDocument"
                    v-mask="['###.###.###-##', '##.###.###/####-##']"
                    placeholder="CPF/CNPJ"
                    name="document"
                    maxlength="18"
                  />

                  <small
                    class="text-danger"
                    v-if="
                      !$v.splitter.bankAccount.taxDocument.required &&
                      $v.splitter.bankAccount.taxDocument.$dirty
                    "
                    >Campo obrigatório</small
                  >
                  <small
                    class="text-danger"
                    v-if="!$v.splitter.bankAccount.taxDocument.cpfCnpj"
                    >O campo CPF/CNPJ está invalido</small
                  >
                </div>
                <div class="form-group col-12 col-lg-3 mb-4">
                  <label for="inputBankNumber">Banco</label>
                  <select
                    class="form-control text-center"
                    name="bankNumber"
                    v-model="splitter.bankAccount.bankNumber"
                  >
                    <option value>Selecione</option>
                    <option
                      v-for="bank in allBanks"
                      :key="bank.number"
                      :value="bank.number"
                    >
                      {{ bank.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-12 col-lg-3 mb-4">
                  <label for="inputBankAccountType">Tipo de conta *</label>
                  <select
                    class="form-control text-center"
                    name="bankAccountType"
                    v-model="splitter.bankAccount.type"
                  >
                    <option value="Corrente">Corrente</option>
                    <option value="Poupanca">Poupança</option>
                  </select>
                </div>
                <div class="form-group col-4 col-lg-2 mb-4">
                  <label for="inputBankAccountBranch">Agência *</label>
                  <input
                    type="text"
                    maxlength="8"
                    v-model="splitter.bankAccount.bankBranchNumber"
                    placeholder="12345"
                    @input="$v.splitter.bankAccount.bankBranchNumber.$touch()"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-4 col-lg-2 mb-4">
                  <label for="inputBankAccountNumber">Conta *</label>
                  <input
                    type="text"
                    maxlength="10"
                    v-model="splitter.bankAccount.accountNumber"
                    placeholder="12345-6"
                    @input="$v.splitter.bankAccount.accountNumber.$touch()"
                    class="form-control"
                    v-mask="[
                      '#############-X',
                      '############-X',
                      '###########-X',
                      '##########-X',
                      '#########-X',
                      '########-X',
                      '#######-X',
                      '######-X',
                      '#####-X',
                      '####-X',
                      '###-X',
                      '##-X',
                      '#-X',
                    ]"
                  />
                </div>
                <div class="form-group col-4 col-lg-2 mb-4">
                  <label for="inputBankAccountVariation">Variação</label>
                  <input
                    type="text"
                    maxlength="8"
                    v-model="splitter.bankAccount.variation"
                    placeholder="12345678"
                    :disabled="splitter.bankAccount.type === 'corrente'"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex">
              <WhiteLabel class="mb-4 mx-auto">
                <button type="submit" class="btn btn-md btn-orange">
                  {{ isEdit ? 'Atualizar' : 'Cadastrar' }} Splitter
                </button>
              </WhiteLabel>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddressCustomer from './AddressCustomer';
import ContactInformation from './ContactInformation';
import { Splitter, SplitterParams } from '@/models/Splitter';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { cpfCnpj } from '@/validations/cpfCnpj';
import { propName } from '@/validations/propName';
import { onlyEmail } from '@/validations/onlyEmail';
import { alphaNumeric } from '@/validations/alphaNumeric';
import { address } from '@/validations/address';
import { phone } from '@/validations/phone';
import SplitterApi from '@/services/v2/SplitterApi';
import ZipCodeApi from '@/services/ZipCodeApi';
import BanksApi from '@/services/v2/BanksApi';
import IbgeApi from '@/services/IbgeApi';
import Datepicker from 'vuejs-datepicker';
import WhiteLabel from '@/components/shared/WhiteLabel';
import Checkbox from '@/components/shared/Checkbox';
import SplitterError from '@/errors/SplitterError';

export default {
  name: 'SplitterForm',

  components: {
    WhiteLabel,
  },

  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    splitterEdit: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    splitter: new Splitter(),
    listStates: [],
    listCities: [],
    listBanks: [],
    isDisabledAddress: false,
    isDisabledAddressLine1: false,
    isDisabledDistrict: false,
    isDisabledCity: false,
    isDisabledState: false,
  }),

  computed: {
    nameIsInvalid() {
      return this.$v.splitter.name.$invalid && this.$v.splitter.name.$dirty;
    },
    taxDocumentIsInvalid() {
      return (
        this.$v.splitter.bankAccount.taxDocument.$invalid &&
        this.$v.splitter.bankAccount.taxDocument.$dirty
      );
    },
    allCities() {
      return this.listCities;
    },
    allStates() {
      return this.listStates;
    },
    allBanks() {
      return this.listBanks;
    },
  },

  async mounted() {
    if (this.splitterEdit === null) {
      this.splitter.address.state = '';
      this.splitter.address.city = '';
    } else this.splitter = this.splitterEdit;

    await this.searchStates();
    if (this.splitter.address.zipCode) {
      this.searchCities(this.splitter.address.state);
      this.isDisabledAddressLine1 = false;
      this.isDisabledDistrict = false;
      this.isDisabledCity = false;
      this.isDisabledState = false;
    }

    await this.searchBanks();
  },

  methods: {
    changeAnticipatedTransfer(status) {
      this.splitter.anticipatedTransfer = status;
    },
    compareStates(a, b) {
      if (a.sigla < b.sigla) {
        return -1;
      }
      if (a.sigla > b.sigla) {
        return 1;
      }
      return 0;
    },
    async searchStates() {
      const api = new IbgeApi();
      this.listStates = (await api.searchStates()).sort(this.compareStates);
    },
    async searchCities(initials) {
      if (initials !== '' && initials !== null && initials !== undefined) {
        this.listCities = [];
        const selectedState = this.listStates.filter(
          (state) => state.sigla === initials
        );

        const api = new IbgeApi();
        const result = await api.searchCities(selectedState[0].id);
        this.listCities = result;

        return;
      }
      this.listCities = [];
    },
    async searchZipCode(zipCode) {
      const zipCodeWithoutMask = zipCode.replace('-', '');

      if (zipCodeWithoutMask.length === 8) {
        const api = new ZipCodeApi();
        this.$bus.$emit('spinner-run');
        const result = await api.searchByZipCode(zipCodeWithoutMask);

        if (result.erro) {
          this.isDisabledAddressLine1 = false;
          this.isDisabledDistrict = false;
          this.isDisabledCity = false;
          this.isDisabledState = false;

          this.listCities = [];
          this.$bus.$emit('spinner-stop');
          return;
        }
        this.splitter.address.line1 = result.logradouro;
        this.splitter.address.district = result.bairro;
        this.splitter.address.state = result.uf;
        this.splitter.address.cityCode = result.ibge;
        this.isDisabledAddressLine1 = this.splitter.address.line1 !== '';
        this.isDisabledDistrict = this.splitter.address.district !== '';
        this.isDisabledCity = this.splitter.address.cityCode !== '';
        this.isDisabledState = this.splitter.address.state !== '';
        this.searchCities(this.splitter.address.state);
      }
      this.$bus.$emit('spinner-stop');
    },
    async searchBanks() {
      const api = new BanksApi();
      const result = await api.getAll();

      if (result.banks.length) {
        this.listBanks = result.banks;
      }
    },
    setTransferPlan() {
      if (this.splitter.transferDays < 32)
        this.splitter.anticipatedTransfer = true;
    },
    save() {
      var title;
      var message;
      if (this.$v.$invalid) {
        this.$v.$touch();
      } else {
        const api = new SplitterApi();

        this.$bus.$emit('spinner-run');

        if (this.$route.params.splitterId) {
          api
            .update(this.splitter.id, this.splitter)
            .then((result) => {
              if (result.error) {
                const modelError = new SplitterError({
                  error: result.error,
                });

                this.$alert.error('Ops', modelError.getMessage());
                this.$bus.$emit('spinner-stop');
              } else if (result.errors) {
                this.$alert.error('Ops', 'Revise os seus dados de cadastro!');
                this.$bus.$emit('spinner-stop');
              } else {
                this.$bus.$emit('spinner-stop');
                const title = 'Atualização realizada';
                const message = `O splitter ${this.splitter.name} foi atualizado com sucesso.`;
                this.$alert.info(title, message);
                this.$router.push({ name: 'splitter' });
              }
            })
            .catch((result) => {
              let errorMessage = '';

              if (result.response.status === 400) {
                errorMessage =
                  'Falha ao atualizar o splitter. Verifique os dados informados e tente novamente.';
              }

              if (result.response.status === 422) {
                const modelError = new SplitterError(result);

                errorMessage = modelError.getMessage();
              }

              if (result.response.status === 500) {
                errorMessage =
                  'Não foi possível estabelecer conexão com o serviço. Tente novamente mais tarde.';
              }

              this.$alert.error('Ops', errorMessage);
              this.$bus.$emit('spinner-stop');
            });
        } else {
          api
            .create(this.splitter)
            .then((result) => {
              if (result.error) {
                const modelError = new SplitterError({
                  error: result.error,
                });

                this.$alert.error('Ops', modelError.getMessage());
                this.$bus.$emit('spinner-stop');
              } else if (result.errors) {
                this.$alert.error('Ops', 'Revise os seus dados de cadastro!');
                this.$bus.$emit('spinner-stop');
              } else {
                this.$bus.$emit('spinner-stop');
                const title = 'Cadastro Realizado';
                const message = `O splitter ${this.splitter.name} foi cadastrado com sucesso.`;
                this.$alert.info(title, message);
                this.$router.push({ name: 'splitter' });
              }
            })
            .catch((result) => {
              let errorMessage = '';

              if (result.response.status === 400) {
                errorMessage =
                  'Falha ao cadastrar o splitter. Verifique os dados informados e tente novamente.';
              }

              if (result.response.status === 422) {
                const modelError = new SplitterError(result);

                errorMessage = modelError.getMessage();
              }

              if (result.response.status === 500) {
                errorMessage =
                  'Não foi possível estabelecer conexão com o serviço. Tente novamente mais tarde.';
              }

              this.$alert.error('Ops', errorMessage);
              this.$bus.$emit('spinner-stop');
            });
        }
      }
    },
    back() {
      if (this.isEdit) this.$router.push({ name: 'new-splitter' });
      location.reload();
    },
  },

  validations: {
    splitter: {
      name: {
        required,
        alphaNumeric,
      },
      email: {
        onlyEmail,
      },
      confirmEmail: {
        onlyEmail,
      },
      mobile: {
        phone,
      },
      address: {
        line1: {
          address,
          required,
        },
        line2: {
          alphaNumeric,
        },
        district: {
          alphaNumeric,
          required,
        },
        streetNumber: {
          alphaNumeric,
          required,
        },
        zipCode: {
          required,
        },
      },
      bankAccount: {
        holderName: {
          required,
          propName,
        },
        taxDocument: {
          cpfCnpj,
          required,
        },
        accountNumber: {
          alphaNumeric,
          required,
        },
        bankBranchNumber: {
          alphaNumeric,
          required,
        },
        variation: {
          alphaNumeric,
        },
      },
    },
  },
};
</script>
<style scoped>
.input-number {
  max-width: 100px;
}
</style>
