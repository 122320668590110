<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-w-full col-lg-10 col-xl-8">
        <div class="card mt-5">
          <div class="d-flex">
            <h3 class="font-weight-light pull-left ml-3">Novo representante</h3>
            <WhiteLabel class="ml-auto mt-1 pull-right mr-4">
              <router-link :to="{ name: 'seller' }">
                <i class="icon-arrow-left-circle"></i> Voltar
              </router-link>
            </WhiteLabel>
          </div>
          <hr />

          <form action="#" @submit.prevent="save()">
            <div class="card-content mb-5">
              <div class="form-row">
                <div class="form-group col-12 col-w-full col-lg-7 mb-4">
                  <label for="inputNome">Nome *</label>
                  <input
                    id="inputNome"
                    v-model="newHolder.fullName"
                    required
                    type="text"
                    class="form-control"
                    maxlength="120"
                    placeholder="Digite aqui o nome do novo representante"
                    @input="$v.newHolder.fullName.$touch()"
                  />
                  <small
                    v-if="
                      !$v.newHolder.fullName.required &&
                      $v.newHolder.fullName.$dirty
                    "
                    class="text-danger"
                  >
                    Campo obrigatório
                  </small>
                  <small
                    v-else-if="!$v.newHolder.fullName.onlyLetters"
                    class="text-danger"
                  >
                    Permitido somente letras
                  </small>
                  <small
                    v-else-if="!$v.newHolder.fullName.twoPlusWords"
                    class="text-danger"
                  >
                    Nome e sobrenome são necessários
                  </small>
                </div>

                <div class="form-group col-12 col-w-full col-lg-7 mb-4">
                  <label for="inputJobTitle">Cargo *</label>
                  <input
                    id="inputJobTitle"
                    v-model="newHolder.jobTitle"
                    required
                    type="text"
                    class="form-control"
                    maxlength="120"
                    placeholder="Digite aqui o cargo do novo representante"
                    @input="$v.newHolder.jobTitle.$touch()"
                  />
                  <small
                    v-if="
                      !$v.newHolder.jobTitle.required &&
                      $v.newHolder.jobTitle.$dirty
                    "
                    class="text-danger"
                  >
                    Campo obrigatório
                  </small>
                </div>

                <div class="form-group col-12 col-w-full col-lg-5 mb-4">
                  <label for="inputCpf">CPF *</label>

                  <input
                    id="inputCpf"
                    v-model="newHolder.taxDocument"
                    required
                    type="text"
                    class="form-control"
                    v-mask="['###.###.###-##']"
                    placeholder="CPF"
                    maxlength="14"
                    @input="$v.newHolder.taxDocument.$touch()"
                  />
                  <small
                    v-if="
                      !$v.newHolder.taxDocument.required &&
                      $v.newHolder.taxDocument.$dirty
                    "
                    class="text-danger"
                  >
                    Campo obrigatório
                  </small>
                  <small
                    v-if="!$v.newHolder.taxDocument.cpf"
                    class="text-danger"
                  >
                    O CPF está invalido
                  </small>
                </div>

                <div class="form-group col-12 col-w-full col-lg-3 mb-4">
                  <label for="inputMobile">Celular *</label>
                  <input
                    id="inputMobile"
                    v-model="newHolder.mobile"
                    required
                    class="form-control"
                    type="text"
                    placeholder="Celular"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    @input="$v.newHolder.mobile.$touch()"
                  />
                  <small
                    v-if="
                      !$v.newHolder.mobile.required &&
                      $v.newHolder.mobile.$dirty
                    "
                    class="text-danger"
                  >
                    Campo obrigatório
                  </small>
                  <small v-if="!$v.newHolder.mobile.phone" class="text-danger">
                    O Celular está invalido
                  </small>
                </div>

                <div class="form-group col-12 col-w-full col-lg-3 mb-4">
                  <label for="inputPhone">Telefone</label>
                  <input
                    id="inputPhone"
                    v-model="newHolder.phone"
                    class="form-control"
                    type="text"
                    placeholder="Telefone"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                  />
                  <small v-if="!$v.newHolder.phone.phone" class="text-danger">
                    O Telefone está invalido
                  </small>
                </div>

                <div class="form-group col-12 col-lg-3 mb-4">
                  <label for="inputGender">Gênero</label>
                  <select
                    id="inputGender"
                    v-model="newHolder.gender"
                    class="form-control text-center"
                  >
                    <option value="F">Feminino</option>
                    <option value="M">Masculino</option>
                  </select>
                </div>

                <div class="form-group col-12 col-w-full col-lg-6 mb-4">
                  <label for="inputEmail">Email *</label>
                  <input
                    id="inputEmail"
                    v-model="newHolder.email"
                    required
                    class="form-control"
                    type="text"
                    placeholder="Digite o Email"
                    maxlength="120"
                    @input="$v.newHolder.email.$touch()"
                  />
                  <small
                    v-if="
                      !$v.newHolder.email.required && $v.newHolder.email.$dirty
                    "
                    class="text-danger"
                  >
                    Campo obrigatório
                  </small>
                  <small
                    v-if="!$v.newHolder.email.onlyEmail"
                    class="text-danger"
                  >
                    O Email está invalido
                  </small>
                </div>

                <div class="form-group col-12 col-w-full col-lg-6 mb-4">
                  <label for="inputConfirmEmail">Confirme o Email *</label>
                  <input
                    id="inputConfirmEmail"
                    v-model="confirmEmail"
                    class="form-control"
                    type="text"
                    placeholder="Confirme o Email"
                  />
                  <small v-if="!doesConfirmEmailMatch" class="text-danger">
                    Os emails diferem
                  </small>
                </div>

                <div class="form-group col-12 col-w-full col-lg-7 mb-4">
                  <label for="inputMothersName">Nome da mãe *</label>
                  <input
                    id="inputMothersName"
                    v-model="newHolder.mothersName"
                    required
                    type="text"
                    class="form-control"
                    maxlength="120"
                    placeholder="Digite aqui o nome da mãe do novo representante"
                    @input="$v.newHolder.mothersName.$touch()"
                  />
                  <small
                    v-if="
                      !$v.newHolder.mothersName.required &&
                      $v.newHolder.mothersName.$dirty
                    "
                    class="text-danger"
                  >
                    Campo obrigatório
                  </small>
                  <small
                    v-if="!$v.newHolder.mothersName.onlyLetters"
                    class="text-danger"
                  >
                    Permitido somente letras
                  </small>
                  <small
                    v-if="!$v.newHolder.mothersName.twoPlusWords"
                    class="text-danger"
                  >
                    Nome e sobrenome são necessários
                  </small>
                </div>

                <div class="form-group col-12 col-lg-5 mb-4">
                  <label for="inputBirthDate">Data de nascimento *</label>
                  <input
                    id="inputBirthDate"
                    v-model="birthDate"
                    required
                    type="date"
                    class="form-control"
                    @input="$v.birthDate.$touch()"
                  />
                  <small
                    v-if="!isBirthDateValid && $v.birthDate.$dirty"
                    class="text-danger"
                  >
                    A data está invalida
                  </small>
                </div>
              </div>
              <div class="d-flex">
                <WhiteLabel class="mb-4 mx-auto">
                  <button type="submit" class="btn btn-md btn-orange">
                    Confirmar
                  </button>
                </WhiteLabel>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Splitter, SplitterParams } from '@/models/Splitter';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { cpf } from '@/validations/cpf';
import { onlyEmail } from '@/validations/onlyEmail';
import { onlyLetters } from '@/validations/onlyLetters';
import { phone } from '@/validations/phone';
import { twoPlusWords } from '@/validations/twoPlusWords';
import WhiteLabel from '@/components/shared/WhiteLabel';
import swal from 'sweetalert2';
import AccountApi from '@/services/AccountApi';
import ChangeSellerHolderError from '@/errors/ChangeSellerHolderError';

export default {
  name: 'ChangeSellerHolder',

  components: {
    WhiteLabel,
  },

  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    splitterEdit: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    splitter: new Splitter(),
    newHolder: {
      taxDocument: '',
      fullName: '',
      mothersName: '',
      email: '',
      mobile: '',
      phone: '',
      birthDate: new Date(),
      gender: 'F',
      jobTitle: '',
      forgetPreviousHolderData: false,
    },
    birthDate: '',
    confirmEmail: '',
  }),

  computed: {
    parsedBirthDate() {
      return new Date(this.birthDate);
    },
    isBirthDateValid() {
      return this.parsedBirthDate != 'Invalid Date';
    },
    doesConfirmEmailMatch() {
      return this.confirmEmail === this.newHolder.email;
    },
  },

  async mounted() {},

  methods: {
    save() {
      const api = new AccountApi();
      if (this.$v.$invalid || !this.doesConfirmEmailMatch) {
        this.$alert.error('Ops', 'Dados de entrada inválidos!');
        this.$v.$touch();
        return;
      }

      this.newHolder.birthDate = this.parsedBirthDate;
      swal({
        title:
          'Deseja esquecer os dados do antigo representante legal desta conta?',
        html: '<p style="font-size:80%;">Essa opção dispõem sobre o tratamento dos dados pessoais do representante legal da empresa, objetivando proteger seus direitos fundamentais de liberdade e de privacidade e o livre desenvolvimento da personalidade da pessoa natural, em concordância com o art. 5º da Lei Geral de Proteção de Dados (LGPD - Lei Nº 13.709, de 14 de agosto de 2018)</p><input type="radio" id="forget-holder-data" name="forget-holder-data"> <label for="forget-holder-data">Esquecer os dados</label><br><input type="radio" id="dont-forget-holder-data" name="forget-holder-data" checked="checked" > <label for="dont-forget-holder-data">Não esquecer os dados</label>',
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        preConfirm: () => {
          this.newHolder.forgetPreviousHolderData = $('#forget-holder-data').is(
            ':checked'
          );
        },
        allowOutsideClick: () => !swal.isLoading(),
      }).then(async (result) => {
        if (result.value) {
          this.$bus.$emit('spinner-run');
          var response = await api.changeSellerHolder(
            this.newHolder,
            this.$route.params.holderId
          );
          this.$bus.$emit('spinner-stop');
          if (response.status == 204) {
            this.$alert.info('Pronto', 'Representante da conta alterado!');
            this.$router.push({ name: 'seller' });
          } else if (response.status == 422) {
            const title = 'Ops';
            const modelError = new ChangeSellerHolderError(response.data);
            const errorMessage = modelError.getMessage();
            this.$alert.error(title, `${errorMessage}`);
          } else if (response.status == 400) {
            const errorMessage = `Erro na validação dos dados - ${response.data.errors[0]}`;
            this.$alert.error('Ops', errorMessage);
          } else {
            this.$alert.error('Ops', 'Ocorreu um erro inesperado');
          }
        }
      });
    },
  },

  validations: {
    newHolder: {
      taxDocument: {
        cpf,
        required,
      },
      fullName: {
        required,
        onlyLetters,
        twoPlusWords,
      },
      mothersName: {
        required,
        onlyLetters,
        twoPlusWords,
      },
      email: {
        required,
        onlyEmail,
      },
      mobile: {
        required,
        phone,
      },
      phone: {
        phone,
      },
      jobTitle: {
        required,
      },
    },
    birthDate: {
      required,
    },
    confirmEmail: {
      required,
    },
  },
};
</script>

<style scoped>
</style>
