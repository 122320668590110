
export class LinkPayment {
  hashId = null
  fullName = null
  document = null
  phoneNumber = null
  email = null
  addressCityCode = null
  addressDistrict = null
  addressLine1 = null
  addressLine2 = null
  addressStreetNumber = null
  addressZipCode = null
  addressCity = null
  addressState = null
  url = null
  payment = 'both'
  status = null
  fixedInstallments = false
  maximumInstallments = 12
  discountCard = null
  discountBankSlip = null
  amount = null
  dueDate = null
  paidIn = null
  canceledIn = null
  contestedIn = null
  holderId = null
  userId = null
  created = null
  updated = null
  billingDetails =[]
}

export class LinkPaymentFilter {
    fullName = ''
    email = ''
    document = ''
    status = []
    index = 0
    limit = 30
}

export class LinkPaymentParams {
  hashId = null;
  fullName = null;
  document = null;
  phoneNumber = null;
  email = null;
  addressCityCode = null
  addressDistrict = null
  addressLine1 = null
  addressLine2 = null
  addressStreetNumber = null
  addressZipCode = null
  url = null;
  payment = null;
  status = null;
  fixedInstallments = false;
  maximumInstallments = 1;
  discountCard = null;
  discountBankSlip = null;
  amount = null;
  dueDate = null;
  paidIn = null;
  canceledIn = null;
  contestedIn = null;
  holderId = null;
  userId = null;
  created = null;
  updated = null;
  billingDetails =[];

  constructor(LinkPayment) {
    this.hashId = LinkPayment.hashId !== '' ? LinkPayment.hashId : '';
    this.fullName = LinkPayment.fullName;
    this.document = LinkPayment.document;
    this.phoneNumber = LinkPayment.phoneNumber;
    this.email = LinkPayment.email;
    this.addressCityCode = LinkPayment.addressCityCode;
    this.addressDistrict = LinkPayment.addressDistrict;
    this.addressLine1 = LinkPayment.addressLine1;
    this.addressLine2 = LinkPayment.addressLine2;
    this.addressStreetNumber = LinkPayment.addressStreetNumber;
    this.addressZipCode = LinkPayment.addressZipCode;
    this.url = LinkPayment.Url;
    this.payment = LinkPayment.payment;
    this.status = LinkPayment.status;
    this.fixedInstallments = LinkPayment.fixedInstallments;
    this.maximumInstallments = LinkPayment.maximumInstallments;
    this.discountCard = LinkPayment.discountCard;
    this.discountBankSlip = LinkPayment.discountBankSlip;
    this.amount = LinkPayment.amount;
    this.dueDate = LinkPayment.dueDate;
    this.paidIn = LinkPayment.paidIn;
    this.canceledIn = LinkPayment.canceledIn;
    this.contestedIn = LinkPayment.contestedIn;
    this.holderId = LinkPayment.holderId;
    this.userId = LinkPayment.userId;
    this.created = LinkPayment.created;
    this.updated = LinkPayment.updated;
    this.billingDetails = LinkPayment.billingDetails;
  }
}

export class LinkPaymentList {
    linkpayments = [];
    count = 0;
}
