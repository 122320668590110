<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-w-full col-lg-10 col-xl-8">
        <div class="card mt-5">
          <div class="d-flex">
            <h3 class="font-weight-light ml-3 pull-left">Pessoa Jurídica</h3>
            <WhiteLabel class="ml-auto mt-1 pull-right mr-4">
              <router-link :to="{ name: 'customer' }">
                <i class="icon-arrow-left-circle"></i> Voltar
              </router-link>
            </WhiteLabel>
          </div>
          <hr />
          <form action="#" @submit.prevent="save()">
            <div class="card-content mb-5">
              <div class="form-row">
                <div class="col-12 col-w-full">
                  <WhiteLabel>
                    <h4 class="font-weight-light blue">Dados do Cliente</h4>
                  </WhiteLabel>
                </div>
                <div
                  class="form-group col-12 col-w-full col-lg-7 mb-4"
                  :class="{ 'has-error': nameIsInvalid }"
                >
                  <label for="inputNome">Razão social da empresa*</label>
                  <input
                    type="text"
                    class="form-control"
                    @input="$v.customer.fullName.$touch()"
                    v-model="customer.fullName"
                    placeholder="Digite aqui a razão social da empresa"
                  />
                  <small
                    class="text-danger"
                    v-if="
                      !$v.customer.fullName.required &&
                      $v.customer.fullName.$dirty
                    "
                    >Campo obrigatório</small
                  >
                  <small
                    class="text-danger"
                    v-if="!$v.customer.fullName.propName"
                    >Permitido somente letras</small
                  >
                </div>
                <div
                  class="form-group col-12 col-w-full col-lg-5 mb-4"
                  :class="{ 'has-error': taxDocumentIsInvalid }"
                >
                  <label for="inputCNPJ">CNPJ*</label>
                  <input
                    type="text"
                    class="form-control text-center"
                    @input="$v.customer.taxDocument.$touch()"
                    v-model="customer.taxDocument"
                    v-mask="'##.###.###/####-##'"
                    placeholder="Digite aqui o CNPJ da empresa"
                  />
                  <small
                    class="text-danger"
                    v-if="
                      !$v.customer.taxDocument.required &&
                      $v.customer.taxDocument.$dirty
                    "
                    >Campo obrigatório</small
                  >
                  <small
                    class="text-danger"
                    v-if="!$v.customer.taxDocument.cnpj"
                    >O campo CNPJ está invalido</small
                  >
                </div>
                <div class="form-group col-12 col-w-full col-lg-7 mb-4">
                  <label for="inputNickName">Nome fantasia da empresa</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Digite aqui a o nome fantasia da empresa"
                    v-model="customer.tradeName"
                    maxlength="120"
                  />
                </div>
                <div class="form-group col-12 col-w-full col-lg-5 mb-4">
                  <label for="inputBusinessActivedId"
                    >Principal ramo de atividade</label
                  >
                  <select
                    class="form-control text-center"
                    name="businessActivityId"
                    v-model="customer.businessActivityId"
                  >
                    <option value>Selecione</option>
                    <option
                      v-for="branch in listbranchOfActivities"
                      :key="branch.id"
                      :value="branch.id"
                    >
                      {{ branch.name }}
                    </option>
                  </select>
                  <small class="text-danger" v-if="businessIsInvalid"
                    >O campo é obrigatório.</small
                  >
                </div>
              </div>
            </div>
            <div class="card-content mb-5">
              <representative-customer :customer="customer" />
            </div>
            <div class="card-content mb-5">
              <contact-information
                :customer="customer"
                @inputEmail="changeEmail"
                @inputSms="changeSms"
              />
            </div>
            <div class="card-content mb-5">
              <address-customer :isJuridic="true" :customer="customer" />
            </div>
            <div class="d-flex">
              <WhiteLabel class="mx-auto mb-4">
                <button type="submit" class="btn btn-md btn-orange">
                  {{ isEdit ? "Atualizar" : "Cadastrar" }} Cliente
                </button>
              </WhiteLabel>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddressCustomer from './AddressCustomer';
import ContactInformation from './ContactInformation';
import RepresentativeCustomer from './RepresentativeCustomer';
import { Customer, CustomerParams } from '@/models/Customer';
import { Representative } from '@/models/Representative';
import { required, email } from 'vuelidate/lib/validators';
import { cnpj } from '@/validations/cnpj';
import { cpf } from '@/validations/cpf';
import { alphaNumeric } from '@/validations/alphaNumeric';
import { address } from '@/validations/address';
import { propName } from '@/validations/propName';
import BusinessApi from '@/services/BusinessApi';
import AccountApi from '@/services/AccountApi';
import CustomerError from '@/errors/CustomerError';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppCorporateCustomer',
  created() {
    if (!this.customerEdit) {
      this.customer.representative = new Representative();
      this.customer.representative.gender = '';
      this.customer.state = '';
      this.customer.city = '';
    }
  },
  async mounted() {
    await this.branchOfActivities();
    this.customer.businessActivityId = '';
  },
  data() {
    return {
      customer: this.customerEdit === null ? new Customer() : this.customerEdit,
      listbranchOfActivities: [],
      businessIsInvalid: false,
      pausedEmail: null,
      pausedSms: null,
    };
  },
  validations: {
    customer: {
      fullName: {
        required,
        propName,
      },
      taxDocument: {
        cnpj,
        required,
      },
      email: {
        email,
      },
      representative: {
        name: {
          propName,
        },
        taxDocument: {
          cpf,
        },
      },
      addressLine1: {
        address,
      },
      addressLine2: {
        alphaNumeric,
      },
      district: {
        alphaNumeric,
      },
      addressNumber: {
        alphaNumeric,
      },
    },
  },
  components: {
    AddressCustomer,
    ContactInformation,
    RepresentativeCustomer,
    WhiteLabel,
  },
  computed: {
    nameIsInvalid() {
      return (
        this.$v.customer.fullName.$invalid && this.$v.customer.fullName.$dirty
      );
    },
    taxDocumentIsInvalid() {
      return (
        this.$v.customer.taxDocument.$invalid &&
        this.$v.customer.taxDocument.$dirty
      );
    },
  },
  methods: {
    async changeEmail(email) {
      this.pausedEmail = email;
    },
    async changeSms(sms) {
      this.pausedSms = sms;
    },
    async save() {
      var title;
      var message;
      if (this.$v.$invalid) {
        this.$v.$touch();
      } else {
        const api = new BusinessApi();
        const customerParams = new CustomerParams({ ...this.customer });
        this.$bus.$emit('spinner-run');
        if (this.$route.params.customerId) {
          var result = await api.customers.update(customerParams);
          if (
            this.pausedEmail !== null &&
            this.pausedEmail !== this.customer.emailNotificationPaused
          ) {
            result = await api.customers.pauseEmail(
              this.$route.params.customerId
            );
          }
          if (
            this.pausedSms !== null &&
            this.pausedSms !== this.customer.mobileNotificationPaused
          ) {
            result = await api.customers.pauseSms(
              this.$route.params.customerId
            );
          }
          if (result.error) {
            const modelError = new CustomerError(result);
            this.$alert.error('Ops', modelError.getMessage());
            this.$bus.$emit('spinner-stop');
          } else {
            title = 'Cadastro Atualizado';
            message = `O cliente ${this.customer.fullName} foi atualizado com sucesso.`;
            this.$alert.info(title, message);
            this.$router.push({ name: 'customer' });
          }
        } else {
          var result = await api.customers.save(customerParams);
          if (
            this.pausedEmail !== null &&
            this.pausedEmail !== this.customer.emailNotificationPaused
          ) {
            result = await api.customers.pauseEmail(result.id);
          }
          if (
            this.pausedSms !== null &&
            this.pausedSms !== this.customer.mobileNotificationPaused
          ) {
            result = await api.customers.pauseSms(result.id);
          }
          if (result.error) {
            const modelError = new CustomerError(result);
            this.$alert.error('Ops', modelError.getMessage());
            this.$bus.$emit('spinner-stop');
          } else {
            title = 'Cadastro Realizado';
            message = `O cliente ${this.customer.fullName} foi cadastrado com sucesso.`;
            this.$alert.info(title, message);
            this.$router.push({ name: 'customer' });
          }
        }
      }
    },
    back() {
      if (this.isEdit) this.$router.push({ name: 'new-customer' });
      location.reload();
    },
    async branchOfActivities() {
      const api = new AccountApi();
      var result = await api.branchOfActivities();
      this.listbranchOfActivities = result.businessActivities;
    },
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    customerEdit: {
      type: Object,
      default: null,
    },
  },
};
</script>
