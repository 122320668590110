<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-w-full col-lg-10 col-xl-8">
        <div class="card">
          <WhiteLabel class="d-flex">
            <h3 class="font-weight-light ml-3 pull-left">Pessoa Jurídica</h3>

            <router-link
              :to="{ name: 'customer' }"
              class="ml-auto mt-1 pull-right mr-4"
            >
              <i class="icon-arrow-left-circle"></i> Voltar
            </router-link>
          </WhiteLabel>

          <hr />
          <form action="#" @submit.prevent="save()" autocomplete="off">
            <div class="card-content mb-5">
              <div class="form-row">
                <div class="col-12 col-w-full">
                  <WhiteLabel>
                    <h4 class="font-weight-light blue">Dados do Cliente</h4>
                  </WhiteLabel>
                </div>
                <div class="form-group col-12 col-w-full col-lg-7 mb-4">
                  <label for="inputNome">Razão social da empresa</label>
                  <p>{{ customer.fullName }}</p>
                </div>
                <div class="form-group col-12 col-w-full col-lg-5 mb-4">
                  <label for="inputCNPJ">CNPJ</label>
                  <p>{{ customer.taxDocument }}</p>
                </div>
                <div class="form-group col-12 col-w-full col-lg-7 mb-4">
                  <label for="inputNickName">Nome fantasia da empresa</label>
                  <p>{{ customer.tradeName }}</p>
                </div>
                <div class="form-group col-12 col-w-full col-lg-5 mb-4">
                  <label for="inputBusinessActivedId"
                    >Principal ramo de atividade</label
                  >
                  <p>{{ businessActivity }}</p>
                </div>
              </div>
            </div>
            <div class="card-content mb-5">
              <representative-customer :customer="customer" />
            </div>
            <div class="card-content mb-5">
              <contact-information :customer="customer" />
            </div>
            <div class="card-content mb-5">
              <address-customer :isJuridic="true" :customer="customer" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import paggcerto from 'paggcerto-lightbox/src/js';
import AddressCustomer from './AddressCustomer';
import ContactInformation from './ContactInformation';
import RepresentativeCustomer from './RepresentativeCustomer';
import { Customer, CustomerParams } from '@/models/Customer';
import { Representative } from '@/models/Representative';
import BusinessApi from '@/services/BusinessApi';
import AccountApi from '@/services/AccountApi';
import CustomerError from '@/errors/CustomerError';
import { PermissionList } from '@/models/Permission';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppCorporateCustomer',
  created() {
    if (!this.customerEdit) {
      this.customer.representative = new Representative();
    }
  },
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.findCustomer();
    await this.branchOfActivities();
    if (this.whoAmI.user.role !== null) {
      await this.getScopes();
    }
    this.$bus.$emit('spinner-stop');
  },
  data() {
    return {
      permissionList: new PermissionList(),
      customer: new Customer(),
      listbranchOfActivities: [],
      businessActivity: '',
    };
  },
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
      token: 'account/token',
    }),
    paymentsPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'payments'
      );
    },
    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    isAccountActive() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.active;
    },
    salesPermission() {
      if (
        (this.permissionList.permissions[this.paymentsPermissionIndex].grant ===
          'create' ||
          this.isAccountHolder) &&
        this.isAccountActive
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {
    AddressCustomer,
    ContactInformation,
    RepresentativeCustomer,
    WhiteLabel,
  },
  methods: {
    async findCustomer() {
      const api = new BusinessApi();
      var customer = await api.customers.find(this.$route.params.customerId);
      this.customer = customer;
    },
    async branchOfActivities() {
      const api = new AccountApi();
      var result = await api.branchOfActivities();
      this.listbranchOfActivities = result.businessActivities;
      for (var i = 0; i < this.listbranchOfActivities.length; i++) {
        if (
          this.listbranchOfActivities[i].id === this.customer.businessActivityId
        ) {
          this.businessActivity = this.listbranchOfActivities[i].name;
          break;
        }
      }
    },
    async getScopes() {
      this.permissionList.fromScopes(this.whoAmI.user.role.scopes);
    },
    payWithBankSlip() {
      paggcerto.lightbox({
        environment: process.env.VUE_APP_LIGHTBOX,
        token: this.token,
        payment: {
          amount: null,
          replicateAmount: true,
          bankSlipEnabled: true,
          creditEnabled: false,
          debitEnabled: false,
          payers: [
            {
              sellingKey: null,
              fullName: this.customer.fullName,
              taxDocument: this.customer.taxDocument,
            },
          ],
        },
      });
    },
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
