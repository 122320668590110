<template>
  <label class="radio-selector d-flex">
    <input
      type="checkbox"
      :disabled="disabled"
      @change="handle"
      :checked="isChecked"
    />
    <div class="checkbox-badge mt-1 mr-2"></div>
    <h4 class="mb-0 d-flex">{{ label }}</h4>
  </label>
</template>
<script>
import { isRegExp } from 'util';
export default {
  name: 'Checkbox',
  data() {
    return {
      isChecked: false,
    };
  },
  mounted() {
    this.isChecked = this.checked || false;
  },
  methods: {
    handle() {
      this.isChecked = !this.isChecked;

      if (typeof this.onChange === 'function') {
        this.onChange(this.isChecked);
      }
    },
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    checked: Boolean,
    disabled: {
      type: String,
      default: '',
    },
    onChange: {
      type: Function,
      default: () => 1,
    },
  },
};
</script>
