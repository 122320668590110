<template>
  <div>
    <div class="header-box pt-5 pb-4 mb-5">
      <div class="row justify-content-center mx-0">
        <div class="col-12 col-w-full col-xl-10">
          <div class="col-sm-12 mb-4">
            <WhiteLabel>
              <router-link
                :to="{ name: 'new-customer' }"
                class="btn btn-orange btn-pill align-self"
                v-if="customersPermission"
              >
                <i class="icon-plus"></i>
                Novo cliente
              </router-link>
            </WhiteLabel>
          </div>
          <form class="row mx-0" autocomplete="off">
            <div class="col-sm-12 col-lg-3 mb-3">
              <div class="form-group">
                <input
                  type="text"
                  v-model="customerFilter.fullName"
                  class="form-control"
                  name="fullName"
                  maxlength="120"
                  placeholder="Nome"
                />
              </div>
            </div>
            <div class="col-sm-12 col-lg-3 mb-3">
              <div>
                <input
                  type="email"
                  v-model="customerFilter.email"
                  class="form-control"
                  name="email"
                  maxlength="120"
                  placeholder="E-mail"
                />
              </div>
            </div>
            <div class="col-sm-12 col-lg-3 mb-3">
              <div>
                <input
                  type="text"
                  class="form-control"
                  required
                  id="taxDocument"
                  v-model="customerFilter.taxDocument"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  placeholder="CPF/CNPJ"
                  name="document"
                  maxlength="18"
                />
              </div>
            </div>
            <div class="d-flex col-sm-12 col-md-6 col-lg-3 mb-3">
              <WhiteLabel>
                <button
                  @click.prevent="filter"
                  class="btn btn-orange mr-2"
                  :disabled="$v.$invalid"
                >
                  Pesquisar
                </button>
              </WhiteLabel>
              <WhiteLabel>
                <button
                  type="button"
                  @click="resetFilters"
                  class="btn btn-md btn-outline-orange ml-2"
                >
                  Limpar filtro
                </button>
              </WhiteLabel>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div
          class="col-12 col-w-full col-md-12 col-lg-6 col-xl-4"
          v-for="customer of customersAll"
          :key="customer.id"
        >
          <div class="card table-card">
            <div class="card-content">
              <div class="d-flex mb-3">
                <div class="pull-left mr-auto">
                  <span
                    class="badge badge-pill"
                    :class="statusColor(customer.active)"
                    >{{ customer.active ? "ATIVO" : "INATIVO" }}</span
                  >
                </div>
                <div class="pull-right">
                  <i
                    class
                    :class="
                      customer.personType === 'F'
                        ? 'icon-user'
                        : 'icon-briefcase'
                    "
                  ></i>
                </div>
              </div>
              <div class="d-flex mb-3">
                <div class="text-center mx-auto text-truncate">
                  <h5 class="font-weight-light mb-2">
                    {{ customer.fullName | abbreviateName(20) }}
                  </h5>
                  <p class="mb-3 text-muted">{{ customer.taxDocument }}</p>
                  <p class="mb-3 text-muted">{{ customer.email }}</p>
                  <p class="text-white" v-if="customer.email === null">•</p>
                </div>
              </div>
              <div class="d-flex align-self-end mb-1">
                <div class="text-center mx-auto">
                  <div
                    class="dropdown text-center mx-auto"
                    v-if="customersPermission"
                  >
                    <WhiteLabel>
                      <button
                        type="button"
                        class="
                          btn btn-outline-orange btn-small btn-pill
                          dropdown-toggle
                        "
                        data-toggle="dropdown"
                      >
                        Ações
                        <span class="caret"></span>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <router-link
                            v-if="customer.id"
                            class="dropdown-item"
                            :to="{
                              name: 'edit-customer',
                              params: { customerId: customer.id },
                            }"
                            >Editar</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            v-if="customer.personType === 'F'"
                            class="dropdown-item"
                            :to="{
                              name: 'person-customer-details',
                              params: { customerId: customer.id },
                            }"
                            >Detalhes</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            v-if="customer.personType === 'J'"
                            class="dropdown-item"
                            :to="{
                              name: 'corporative-customer-details',
                              params: { customerId: customer.id },
                            }"
                            >Detalhes</router-link
                          >
                        </li>
                        <li v-if="customer.active">
                          <a
                            href="javascript:;"
                            class="dropdown-item"
                            @click.prevent="inactive(customer)"
                            >Inativar</a
                          >
                        </li>
                        <li v-else>
                          <a
                            href="javascript:;"
                            class="dropdown-item"
                            @click.prevent="active(customer)"
                            >Ativar</a
                          >
                        </li>
                        <li class="dropdown-divider"></li>
                        <li>
                          <a
                            href="javascript:;"
                            class="dropdown-item"
                            @click.prevent="remove(customer)"
                            >Excluir</a
                          >
                        </li>
                      </ul>
                    </WhiteLabel>
                  </div>
                  <router-link
                    :to="{
                      name: 'person-customer-details',
                      params: { customerId: customer.id },
                    }"
                    class="btn btn-outline-orange btn-pill"
                    v-if="!customersPermission && customer.personType === 'F'"
                    >Visualizar</router-link
                  >
                  <router-link
                    :to="{
                      name: 'corporative-customer-details',
                      params: { customerId: customer.id },
                    }"
                    class="btn btn-outline-orange btn-pill"
                    v-if="!customersPermission && customer.personType === 'J'"
                    >Visualizar</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="countCustomers === 0">
        <div class="text-center">
          <h4>Nenhum cliente encontrado.</h4>
        </div>
      </div>
      <div class="row justify-content-center mx-0">
        <app-paginator
          :count="countCustomers"
          :perPage="perPage"
          @exchange="turnPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import { email } from 'vuelidate/lib/validators';
import { cpfCnpj } from '@/validations/cpfCnpj';
import BusinessApi from '@/services/BusinessApi';
import AppPaginator from '@/components/theme/Paginator';
import WhiteLabel from '@/components/shared/WhiteLabel';
import CustomerError from '@/errors/CustomerError';
import { PermissionList } from '@/models/Permission';
import { CustomerFilter } from '@/models/Customer';

export default {
  name: 'AppSearchCustomer',
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.search();
    if (this.whoAmI.user.role !== null) {
      await this.getScopes();
    }
    this.$bus.$emit('spinner-stop');
    this.$bus.$emit('refresh-warnings');
  },
  data() {
    return {
      customers: new CustomerFilter(),
      perPage: 12,
      customerFilter: new CustomerFilter(),
      count: 0,
      permissionList: new PermissionList(),
    };
  },
  directives: {
    mask,
  },
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
    }),
    customersPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'business.customers'
      );
    },
    customersPermission() {
      if (
        this.permissionList.permissions[this.customersPermissionIndex].grant ===
          'edit' ||
        (this.isAccountHolder && this.isAccountActive)
      ) {
        return true;
      } else {
        return false;
      }
    },
    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    isAccountActive() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.active;
    },
    customersAll() {
      return this.count === 0 ? [] : this.customers;
    },
    countCustomers() {
      return this.count;
    },
  },
  methods: {
    ...mapActions({
      askWhoAmI: 'account/askWhoAmI',
    }),
    async getScopes() {
      this.permissionList.fromScopes(this.whoAmI.user.role.scopes);
    },
    resetFilters() {
      this.customerFilter = new CustomerFilter();
    },
    async filter(event = undefined) {
      this.$bus.$emit('spinner-run');
      const api = new BusinessApi();
      const result = await api.customers.search(this.customerFilter);
      this.customers = result.customers.slice(0, this.perPage);
      this.count = result.count;
      this.$bus.$emit('spinner-stop');
    },
    async turnPage(numberPage) {
      const fim = numberPage * this.perPage;
      const inicio = fim - this.perPage;
      this.customerFilter.offset.index = inicio;
      await this.search();
    },
    async remove(customer) {
      this.$alert.question(
        'Excluir Cliente',
        `Deseja realmente excluir o cliente ${customer.fullName} ?`
      );
      this.$alert.onConfirm = async() => {
        this.$bus.$emit('spinner-run');
        const api = new BusinessApi();
        api.customers.remove(customer.id).then((result) => {
          if (result.error) {
            this.$alert.error(
              'Ops',
              `O cliente ${customer.fullName} já  foi excluído anteriormente.`
            );
          } else {
            this.$alert.info(
              'Cliente excluído',
              `O cliente ${customer.fullName} foi excluído com sucesso.`
            );
          }
          this.$bus.$emit('spinner-stop');
          this.search();
        });
      };
    },
    async search() {
      this.$bus.$emit('spinner-run');
      this.$data.customerFilter.email = '';
      this.$data.customerFilter.fullName = '';
      this.$data.customerFilter.taxDocument = '';
      const api = new BusinessApi();
      const result = await api.customers.search(this.$data.customerFilter);
      this.customers = result.customers.slice(0, this.perPage);
      this.count = result.count;
      this.$bus.$emit('spinner-stop');
    },
    async inactive(customer) {
      this.$alert.question(
        'Inativar Cliente',
        `Deseja realmente inativar o cliente ${customer.fullName} ?`
      );
      this.$alert.onConfirm = async() => {
        this.$bus.$emit('spinner-run');
        const api = new BusinessApi();
        api.customers.inactive(customer.id).then(() => {
          this.$alert.info(
            'Cliente Inativado',
            `O cliente ${customer.fullName} foi inativado com sucesso.`
          );
          this.$bus.$emit('spinner-stop');
          this.search();
        });
      };
    },
    async active(customer) {
      this.$alert.question(
        'Ativar Cliente',
        `Deseja realmente ativar o cliente ${customer.fullName} ?`
      );
      this.$alert.onConfirm = async() => {
        this.$bus.$emit('spinner-run');
        const api = new BusinessApi();
        api.customers.active(customer.id).then(() => {
          this.$alert.info(
            'Cliente Ativado',
            `O cliente ${customer.fullName} foi ativado com sucesso.`
          );
          this.$bus.$emit('spinner-stop');
          this.search();
        });
      };
    },
    statusColor(type) {
      return {
        'badge-success': type,
        'badge-danger': !type,
      };
    },
  },
  validations: {
    customerFilter: {
      email: { email },
      taxDocument: { cpfCnpj },
    },
  },
  components: {
    AppPaginator,
    WhiteLabel,
  },
};
</script>
