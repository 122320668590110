import { mask } from 'vue-the-mask';
var mixins = {
  directives: {
    mask,
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
};

export { mixins };
