<template>
  <div>
    <div class="header-box pt-5 pb-4 mb-5">
      <div class="row justify-content-center mx-0">
        <div class="col-12 col-w-full col-xl-10">
          <div class="col-sm-12 mb-4" v-if="isAccountHolder">
            <WhiteLabel>
              <router-link
                :to="{ name: 'new-splitter' }"
                class="btn btn-orange btn-pill align-self"
              >
                <i class="icon-plus"></i>
                Novo Splitter
              </router-link>
            </WhiteLabel>
          </div>
          <form class="row mx-0" autocomplete="off">
            <div class="col-sm-12 col-lg-3 mb-3">
              <div class="form-group">
                <input
                  type="text"
                  v-model="splitterFilter.name"
                  class="form-control"
                  name="name"
                  maxlength="120"
                  placeholder="Nome"
                />
              </div>
            </div>

            <div class="d-flex col-sm-12 col-md-6 col-lg-3 mb-3">
              <WhiteLabel>
                <button @click.prevent="filter" class="btn btn-orange mr-2">
                  Pesquisar
                </button>
              </WhiteLabel>
              <WhiteLabel>
                <button
                  type="button"
                  @click="resetFilters"
                  class="btn btn-md btn-outline-orange ml-2"
                >
                  Limpar filtro
                </button>
              </WhiteLabel>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div
          class="col-12 col-w-full col-md-12 col-lg-6 col-xl-4"
          v-for="splitter of splittersAll"
          :key="splitter.id"
        >
          <div class="card table-card">
            <div class="card-content">
              <div class="d-flex mb-3">
                <div class="text-center mx-auto text-truncate">
                  <h5 class="font-weight-light mb-2">
                    {{ splitter.name | abbreviateName(20) }}
                  </h5>
                  <p class="mb-3 text-muted">
                    {{ splitter.transferDays }}
                    {{ splitter.transferDays === 1 ? "dia" : "dias" }}
                    para repasse
                  </p>
                </div>
              </div>
              <div class="d-flex align-self-end mb-1">
                <div class="text-center mx-auto">
                  <div class="dropdown text-center mx-auto">
                    <WhiteLabel>
                      <button
                        type="button"
                        class="
                          btn btn-outline-orange btn-small btn-pill
                          dropdown-toggle
                        "
                        data-toggle="dropdown"
                      >
                        Ações
                        <span class="caret"></span>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <router-link
                            v-if="splitter.id && isAccountHolder"
                            class="dropdown-item"
                            :to="{
                              name: 'edit-splitter',
                              params: { splitterId: splitter.id },
                            }"
                            >Editar</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            class="dropdown-item"
                            :to="{
                              name: 'splitter-details',
                              params: { splitterId: splitter.id },
                            }"
                            >Detalhes</router-link
                          >
                        </li>
                      </ul>
                    </WhiteLabel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="countSplitters === 0">
        <div class="text-center">
          <h4>Nenhum splitter encontrado.</h4>
        </div>
      </div>
      <div class="row justify-content-center mx-0">
        <app-paginator
          :count="countSplitters"
          :perPage="perPage"
          @exchange="turnPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import { email } from 'vuelidate/lib/validators';
import { cpfCnpj } from '@/validations/cpfCnpj';
import BusinessApi from '@/services/BusinessApi';
import SplitterApi from '@/services/v2/SplitterApi';
import AppPaginator from '@/components/theme/Paginator';
import WhiteLabel from '@/components/shared/WhiteLabel';
import CustomerError from '@/errors/CustomerError';
import { PermissionList } from '@/models/Permission';
import { CustomerFilter } from '@/models/Customer';

export default {
  name: 'AppSearchSplitter',
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.search();
    if (this.whoAmI.user.role !== null) {
      await this.getScopes();
    }
    this.$bus.$emit('spinner-stop');
    this.$bus.$emit('refresh-warnings');
  },
  data() {
    return {
      splitters: [],
      perPage: 12,
      splitterFilter: {
        name: '',
        index: 0,
        length: 12,
      },
      count: 0,
      permissionList: new PermissionList(),
    };
  },
  directives: {
    mask,
  },
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
    }),
    customersPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'business.customers'
      );
    },
    customersPermission() {
      if (
        this.permissionList.permissions[this.customersPermissionIndex].grant ===
          'edit' ||
        (this.isAccountHolder && this.isAccountActive)
      ) {
        return true;
      } else {
        return false;
      }
    },
    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    isAccountActive() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.active;
    },
    splittersAll() {
      return this.count === 0 ? [] : this.splitters;
    },
    countSplitters() {
      return this.count;
    },
  },
  methods: {
    ...mapActions({
      askWhoAmI: 'account/askWhoAmI',
    }),
    async getScopes() {
      this.permissionList.fromScopes(this.whoAmI.user.role.scopes);
    },
    resetFilters() {
      this.splitterFilter.name = '';
      this.search();
    },
    async filter(event = undefined) {
      this.$bus.$emit('spinner-run');
      const api = new SplitterApi();
      const result = await api.getAll({
        name: this.$data.splitterFilter.name,
      });
      this.splitters = result.splitters.slice(0, this.perPage);
      this.count = result.count;
      this.$bus.$emit('spinner-stop');
    },
    async turnPage(numberPage) {
      const end = numberPage * this.perPage;
      const start = end - this.perPage;
      this.splitterFilter.index = start;
      await this.search();
    },
    async search() {
      this.$bus.$emit('spinner-run');
      this.$data.splitterFilter.name = '';
      const api = new SplitterApi();
      const result = await api.getAll(this.$data.splitterFilter);
      this.splitters = result.splitters.slice(0, this.perPage);
      this.count = result.count;
      this.$bus.$emit('spinner-stop');
    },
    statusColor(type) {
      return {
        'badge-success': type,
        'badge-danger': !type,
      };
    },
  },
  components: {
    AppPaginator,
    WhiteLabel,
  },
};
</script>
