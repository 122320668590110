<template>
  <div>
    <div class="container" v-if="personTypeSelected === null">
      <div class="row justify-content-center">
        <div class="col-12 col-w-full col-lg-7">
          <div class="card mt-5">
            <div class="d-flex">
              <h3 class="font-weight-light ml-3 pull-left">
                Qual tipo de cliente você está cadastrando?
              </h3>
              <WhiteLabel class="ml-2">
                <a
                  href="#"
                  @click="$router.go(-1)"
                  class="ml-auto mt-1 pull-right mr-4 text-nowrap"
                >
                  <i class="icon-arrow-left-circle"></i> Voltar
                </a>
              </WhiteLabel>
            </div>
            <hr />
            <div class="card-content">
              <div class="p-1">
                <div class="row mb-3 p-0">
                  <label
                    class="radio-selector col-12 col-w-full col-md-6 px-0 mb-3"
                  >
                    <WhiteLabel>
                      <input
                        type="radio"
                        name="tipoCadastro"
                        id="pessoaJuridica"
                        value="F"
                        v-model="personType"
                      />
                      <span class="card-radio">
                        <i class="icon-user"></i>Pessoa Física
                      </span>
                    </WhiteLabel>
                  </label>
                  <label
                    class="radio-selector col-12 col-w-full col-md-6 px-0 mb-3"
                  >
                    <WhiteLabel>
                      <input
                        type="radio"
                        name="tipoCadastro"
                        id="pessoaJuridica"
                        value="J"
                        v-model="personType"
                      />
                      <span class="card-radio">
                        <i class="icon-briefcase"></i>Pessoa Jurídica
                      </span>
                    </WhiteLabel>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-corporate-customer
      v-if="personTypeSelected === 'J'"
      :isEdit="isEdit"
      :customerEdit="customerEdit"
    />
    <app-physical-customer
      v-if="personTypeSelected === 'F'"
      :isEdit="isEdit"
      :customerEdit="customerEdit"
    />
  </div>
</template>
<script>
import AppCorporateCustomer from './CorporateCustomer';
import AppPhysicalCustomer from './PhysicalCustomer';
import BusinessApi from '@/services/BusinessApi';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppCustomerRegistration',
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.findCustomer();
    this.$bus.$emit('spinner-stop');
  },
  data() {
    return {
      personType: null,
      isEdit:
        this.$route.params.customerId !== null &&
        this.$route.params.customerId !== undefined,
      customerEdit: null,
    };
  },
  components: {
    AppCorporateCustomer,
    AppPhysicalCustomer,
    WhiteLabel,
  },
  computed: {
    personTypeSelected() {
      if (!this.personType) {
        return null;
      }
      return this.personType.toUpperCase();
    },
  },
  methods: {
    async findCustomer() {
      if (
        this.$route.params.customerId <= 0 ||
        this.$route.params.customerId === null ||
        this.$route.params.customerId === undefined
      ) {
        this.$router.push({ name: 'new-customer' });
      } else {
        const api = new BusinessApi();
        var customer = await api.customers.find(this.$route.params.customerId);
        this.personType = customer.personType;
        this.customerEdit = customer;
      }
    },
  },
};
</script>
