import { onlyNumbers } from '@/filters/onlyNumbers';
import { withParams } from 'vuelidate/lib';

const cnpj = withParams({ type: 'cnpj' }, cnpj => {
  return validateCNPJ(cnpj);
});

export function validateCNPJ(cnpj) {
  cnpj = onlyNumbers(cnpj);
  if (cnpj === '' || cnpj === null || cnpj === undefined) return true;

  if (cnpj.length !== 14) { return false; }

  if (cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999') { return false; }

  var length = cnpj.length - 2;
  var numbers = cnpj.substring(0, length);
  var digits = cnpj.substring(length);
  var sum = 0;
  var pos = length - 7;

  for (var i = length; i >= 1; i--) {
    sum += numbers.charAt(length - i) * pos--;
    if (pos < 2) { pos = 9; }
  }
  var resultado = sum % 11 < 2 ? 0 : 11 - sum % 11;
  if (resultado != digits.charAt(0)) { return false; }

  length = length + 1;
  numbers = cnpj.substring(0, length);
  sum = 0;
  pos = length - 7;
  for (i = length; i >= 1; i--) {
    sum += numbers.charAt(length - i) * pos--;
    if (pos < 2) { pos = 9; }
  }
  resultado = sum % 11 < 2 ? 0 : 11 - sum % 11;
  if (resultado != digits.charAt(1)) { return false; }

  return true;
}

export { cnpj };
