import CustomApiError from './CustomError';

export default class DeactivateAccountError extends CustomApiError {
  toDictionary() {
    return {
      HOLDER_ALREADY_DEACTIVATED: 'Lojista já desativado.',
      HOLDER_HAS_TRANSFER_TO_RECEIVE: 'Lojista possui transações a receber.',
      HOLDER_HAS_BALANCE: 'Lojista possui saldo.',
      HOLDER_NOT_FOUND: 'Lojista não encontrado.',
    };
  }
}
