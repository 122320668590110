import CustomApiError from './CustomError';

export default class CustomerError extends CustomApiError {
  toDictionary() {
    return {
      DUPLICATE_TAX_DOCUMENT:
        'Já existe um cliente vinculado com esse CPF ou CNPJ.',
      CUSTOMER_NOT_FOUND: 'O cliente não foi encontrado.',
      HAS_LINKED_PAYMENT_SLIPS:
        'O cliente não pode ser removido, porque possui boletos vinculados.',
      PAUSE_NOTIFICATION_FAILED: 'Falha na pausa da notificação',
      DONT_HAVE_EMAIL: 'O email não foi cadastrado',
      DONT_HAVE_MOBILE: 'O numero de celular não foi cadastrado',
    };
  }
}
