<template>
  <div class="container-fluid mt-4 pt-4">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10 col-xl-8">
        <div class="card">
          <div class="d-flex">
            <h3 class="font-weight-light pull-left ml-3">Link de cobrança</h3>
            <WhiteLabel class="pull-right ml-auto">
              <router-link
                :to="{ name: 'linkpayment' }"
                class="ml-auto mt-1 pull-right mr-4"
              >
                <i class="icon-arrow-left-circle" /> Voltar
              </router-link>
            </WhiteLabel>
          </div>
          <hr />
          <form action="#" @submit.prevent="save()" autocomplete="off">
            <div class="card-content mb-5">
              <div class="form-row">
                <div class="col-sm-12">
                  <WhiteLabel>
                    <h4 class="font-weight-light blue">
                      Infomações do cliente
                    </h4>
                  </WhiteLabel>
                </div>
                <div class="form-group col-12 col-lg-8 mb-4">
                  <label for="inputNome">Nome/Razão social *</label>
                  <input
                    type="text"
                    class="form-control"
                    @input="$v.linkPayment.fullName.$touch()"
                    v-model="linkPayment.fullName"
                    maxlength="80"
                    placeholder="Digite aqui o nome do cliente"
                  />
                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.fullName.required &&
                      $v.linkPayment.fullName.$dirty
                    "
                  >
                    Campo obrigatório
                  </small>
                </div>
                <div class="form-group col-12 col-lg-4 mb-4">
                  <label for="inputDefault">CPF/CNPJ *</label>
                  <input
                    type="text"
                    class="form-control"
                    @input="$v.linkPayment.document.$touch()"
                    v-model="linkPayment.document"
                    v-mask="['###.###.###-##', '##.###.###/####-##']"
                    placeholder="CPF/CNPJ"
                    name="document"
                    maxlength="18"
                  />
                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.document.required &&
                      $v.linkPayment.document.$dirty
                    "
                  >
                    Campo obrigatório
                  </small>
                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.document.cpfCnpj &&
                      $v.linkPayment.document.$dirty
                    "
                  >
                    CPF/CNPJ inválido.
                  </small>
                </div>
                <div
                  class="form-group col-12 col-lg-8 mb-4"
                  :class="{ 'has-error': emailIsInvalid }"
                >
                  <label for="inputEmail">Email *</label>
                  <input
                    type="email"
                    class="form-control"
                    v-model="linkPayment.email"
                    maxlength="100"
                    placeholder="Digite aqui o email"
                    @input="$v.linkPayment.email.$touch()"
                  />
                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.email.required &&
                      $v.linkPayment.email.$dirty
                    "
                  >
                    Campo obrigatório
                  </small>
                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.email.email && $v.linkPayment.email.$dirty
                    "
                  >
                    E-mail inválido.
                  </small>
                </div>
                <div class="form-group col-12 col-lg-4 mb-4">
                  <label for="inputPhoneNumber">Celular *</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="linkPayment.phoneNumber"
                    maxlength="15"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    placeholder="(00) 0 0000-0000"
                    @input="$v.linkPayment.phoneNumber.$touch()"
                  />
                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.phoneNumber.required &&
                      $v.linkPayment.phoneNumber.$dirty
                    "
                  >
                    Campo obrigatório
                  </small>
                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.phoneNumber.phone &&
                      $v.linkPayment.phoneNumber.$dirty
                    "
                  >
                    Celular inválido.
                  </small>
                </div>
                <div class="col-sm-12">
                  <WhiteLabel>
                    <h4 class="font-weight-light blue">
                      Endereço de cobrança do cliente
                    </h4>
                  </WhiteLabel>
                </div>
                <div class="form-group col-12 col-lg-4 mb-4">
                  <label for="inputNome">CEP *</label>
                  <input
                    type="text"
                    class="form-control"
                    v-mask="'#####-###'"
                    v-model="linkPayment.addressZipCode"
                    maxlength="9"
                    placeholder="Digite aqui o CEP"
                    @input="$v.linkPayment.addressZipCode.$touch()"
                    @change="searchZipCode(linkPayment.addressZipCode)"
                  />
                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.addressZipCode.required &&
                      $v.linkPayment.addressZipCode.$dirty
                    "
                  >
                    Campo obrigatório
                  </small>
                </div>
                <div class="form-group col-12 col-lg-8 mb-4">
                  <label for="inputNome">Rua/Avenida/Logradouro *</label>
                  <input
                    type="text"
                    class="form-control"
                    @input="$v.linkPayment.addressLine1.$touch()"
                    v-model="linkPayment.addressLine1"
                    maxlength="150"
                    placeholder="Digite aqui a Rua/Avenida/Logradouro"
                  />
                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.addressLine1.required &&
                      $v.linkPayment.addressLine1.$dirty
                    "
                  >
                    Campo obrigatório
                  </small>
                </div>
                <div class="form-group col-12 col-lg-4 mb-4">
                  <label for="inputNome">Número *</label>
                  <input
                    type="text"
                    class="form-control"
                    @input="$v.linkPayment.addressStreetNumber.$touch()"
                    v-model="linkPayment.addressStreetNumber"
                    maxlength="20"
                    placeholder="Digite aqui o número"
                  />
                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.addressStreetNumber.required &&
                      $v.linkPayment.addressStreetNumber.$dirty
                    "
                  >
                    Campo obrigatório
                  </small>
                </div>
                <div class="form-group col-12 col-lg-8 mb-4">
                  <label for="inputNome">Complemento</label>
                  <input
                    type="text"
                    class="form-control"
                    @input="$v.linkPayment.addressLine2.$touch()"
                    v-model="linkPayment.addressLine2"
                    maxlength="80"
                    placeholder="Digite aqui o complemento"
                  />
                </div>
                <div class="form-group col-12 col-lg-4 mb-4">
                  <label for="inputNome">Bairro *</label>
                  <input
                    type="text"
                    class="form-control"
                    @input="$v.linkPayment.addressDistrict.$touch()"
                    v-model="linkPayment.addressDistrict"
                    maxlength="50"
                    placeholder="Digite aqui o bairro"
                  />
                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.addressDistrict.required &&
                      $v.linkPayment.addressDistrict.$dirty
                    "
                  >
                    Campo obrigatório
                  </small>
                </div>
                <div class="form-group col-4 col-lg-2 mb-4">
                  <label for="inputState">Estado(UF) *</label>
                  <select
                    v-model="state"
                    :disabled="isDisabledState"
                    class="form-control text-center"
                    name="state"
                    @change="searchCities(state)"
                  >
                    <option
                      v-for="state in allStates"
                      :key="state.id"
                      :value="state.sigla"
                    >
                      {{ state.sigla }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-12 col-lg-4 mb-4">
                  <label for="inputState">Cidade *</label>
                  <select
                    class="form-control text-center"
                    name="state"
                    v-model="linkPayment.addressCityCode"
                  >
                    <option :value="null" disabled>Selecione</option>
                    <option
                      v-for="city in allCities"
                      :key="city.id"
                      :value="city.id"
                    >
                      {{ city.nome }}
                    </option>
                  </select>
                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.addressCityCode.required &&
                      $v.linkPayment.addressCityCode.$dirty
                    "
                  >
                    Campo obrigatório
                  </small>
                </div>
                <input v-model="linkPayment.addressCity" type="hidden" />
              </div>
              <div class="form-row mt-4">
                <div class="col-sm-12">
                  <WhiteLabel>
                    <h4 class="font-weight-light blue">Produtos/serviços</h4>
                  </WhiteLabel>
                </div>
              </div>
              <div
                v-for="(paymentDetail, i) of linkPayment.billingDetails"
                :key="i"
                class="form-row pb-4"
              >
                <div class="form-group col-12 col-lg-2">
                  <label for="inputDefault">Identificador</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="paymentDetail.sku"
                    name="sku"
                    placeholder
                  />
                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.billingDetails.$each[0].sku.required &&
                      $v.linkPayment.billingDetails.$each[0].sku.$dirty
                    "
                  >
                  Campo obrigatório.
                  </small>
                </div>
                <div class="form-group col-12 col-lg-4">
                  <label for="inputState">Categoria do Produto</label>
                  <select
                    class="form-control text-center"
                    name="state"
                    v-model="paymentDetail.code"
                  >
                    <option value>Selecione</option>
                    <option
                      v-for="category in groups.categoriesProduct"
                      :key="category.code"
                      :value="category.code"
                    >
                      {{ category.name }}
                    </option>
                  </select>
                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.billingDetails.$each[0].code.required &&
                      $v.linkPayment.billingDetails.$each[0].code.$dirty
                    "
                  >
                  Campo obrigatório.
                  </small>
                </div>
                <div class="form-group col-12 col-lg-2">
                  <label for="inputDefault">Valor unitário</label>
                  <div class="input-group">
                    <money
                      class="form-control"
                      v-model="paymentDetail.price"
                      v-bind="money"
                    />
                    <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.billingDetails.$each[0].price.required &&
                      $v.linkPayment.billingDetails.$each[0].price.$dirty
                    "
                  >
                  Campo obrigatório.
                  </small>
                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.billingDetails.$each[0].price.minValue &&
                      $v.linkPayment.billingDetails.$each[0].price.$dirty
                    "
                  >
                  Valor mínimo para o link de cobrança é de R$6,00.
                  </small>
                  </div>
                </div>
                <div class="form-group col-12 col-lg-2">
                  <label for="inputDefault">Quantidade</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="paymentDetail.quantity"
                    name="quantity"
                    v-mask="'#####.##'"
                  />
                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.billingDetails.$each[0].quantity.required &&
                      $v.linkPayment.billingDetails.$each[0].quantity.$dirty
                    "
                  >
                  Campo obrigatório.
                  </small>
                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.billingDetails.$each[0].quantity.minValue &&
                      $v.linkPayment.billingDetails.$each[0].quantity.$dirty
                    "
                  >
                  Valor mínimo é de 1.
                  </small>
                </div>
                <div class="form-group col-12 col-lg-10">
                  <label for="inputDefault">Descrição</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="paymentDetail.description"
                    name="description"
                  />

                </div>
                <div class="form-group col-1">
                  <label for="inputDefault"></label>
                  <div class="input-group">
                    <button
                      @click.prevent="addlbillingDetailsAll(index)"
                      class="btn btn-link"
                      v-if="index !== 0"
                    >
                      <i class="fa fa-minus text-danger" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-12 col-lg-2">
                  <WhiteLabel>
                    <a
                      href="#"
                      class="ml-auto mt-1 mr-4"
                      @click.prevent="addlbillingDetailsAll(index)"
                    >
                      <i class="icon-plus" /> Adicionar
                    </a>
                  </WhiteLabel>
                </div>
              </div>
              <div class="form-row mb-5">
                <div class="col-sm-12">
                  <WhiteLabel>
                    <h4 class="font-weight-light blue">
                      Configurações de pagamento
                    </h4>
                  </WhiteLabel>
                </div>
                <div class="form-group col">
                  <label for="inputDuaDate">Forma de pagamento *</label>
                  <select class="form-control" v-model="linkPayment.payment">
                    <option :value="'both'">Cartão de crédito ou boleto</option>
                    <option :value="'card'">Somente cartão de crédito</option>
                    <option :value="'bankSlip'">Somente boleto</option>
                  </select>
                </div>
                <div class="form-group col">
                  <label>Data de vencimento *</label>
                  <datepicker
                    v-model="linkPayment.dueDate"
                    input-class="form-control text-center"
                    :calendar-button="false"
                    calendar-button-icon="icon-calendar"
                    language="pt-br"
                    format="dd/MM/yyyy"
                    placeholder="00/00/0000"
                    @input="$v.linkPayment.dueDate.$touch()"
                  />
                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.dueDate.todayOrAfter &&
                      $v.linkPayment.dueDate.$dirty
                    "
                  >
                    Data inválida.
                  </small>

                  <small
                    class="text-danger"
                    v-if="
                      !$v.linkPayment.dueDate.required &&
                      $v.linkPayment.dueDate.$dirty
                    "
                  >
                  Campo obrigatório.
                  </small>
                </div>
              </div>
              <div
                class="form-row mb-5"
                v-if="linkPayment.payment !== 'bankSlip'"
              >
                <div class="col-sm-12">
                  <WhiteLabel>
                    <h4 class="font-weight-light blue">Opções de cartão</h4>
                  </WhiteLabel>
                </div>
                <div class="form-group col-4 pr-4">
                  <label for="inputDefault">Desconto</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="linkPayment.discountCard"
                      name="discountCard"
                      placeholder="0"
                      v-mask="['##.##', '#.##']"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <small
                    class="text-danger"
                    v-if="!$v.linkPayment.discountCard.between"
                  >
                    Desconto inválido
                  </small>
                </div>
                <div class="form-group col-5">
                  <label>Parcelamento</label>
                  <select
                    class="form-control"
                    v-model="linkPayment.fixedInstallments"
                  >
                    <option :value="false">Cliente escolhe parcelas</option>
                    <option :value="true">Parcelas fixas</option>
                  </select>
                </div>
                <div class="form-group col-3">
                  <label>
                    <span v-if="linkPayment.fixedInstallments">
                      Nº de parcelas
                    </span>
                    <span v-else>Nº máximo de parcelas</span>
                  </label>
                  <select
                    class="form-control"
                    v-model="linkPayment.maximumInstallments"
                  >
                    <option :value="1">1</option>
                    <option :value="2">2</option>
                    <option :value="3">3</option>
                    <option :value="4">4</option>
                    <option :value="5">5</option>
                    <option :value="6">6</option>
                    <option :value="7">7</option>
                    <option :value="8">8</option>
                    <option :value="9">9</option>
                    <option :value="10">10</option>
                    <option :value="11">11</option>
                    <option :value="12">12</option>
                  </select>
                </div>
              </div>
              <div class="form-row" v-if="linkPayment.payment !== 'card'">
                <div class="col-sm-12">
                  <WhiteLabel>
                    <h4 class="font-weight-light blue">Opções de boleto</h4>
                  </WhiteLabel>
                </div>
                <div class="form-group col-4 pr-4">
                  <label for="inputDefault">Desconto</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      v-model="linkPayment.discountBankSlip"
                      name="discountBankSlip"
                      placeholder="0"
                      v-mask="['##.##', '#.##']"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <small
                    class="text-danger"
                    v-if="!$v.linkPayment.discountBankSlip.between"
                  >
                    Desconto inválido
                  </small>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <WhiteLabel class="mb-4 px-4 mx-auto">
                <button
                  type="submit"
                  class="btn btn-orange"
                >
                  Gerar link
                </button>
              </WhiteLabel>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LinkPayment } from '@/models/LinkPayment';
import BusinessApi from '@/services/BusinessApi';
import { required, email, between, minValue } from 'vuelidate/lib/validators';
import { cpfCnpj } from '@/validations/cpfCnpj';
import { phone } from '@/validations/phone';
import Datepicker from 'vuejs-datepicker';
import WhiteLabel from '@/components/shared/WhiteLabel';
import PaymentsApi from '@/services/v3/PaymentsApi';
import AccountApi from '@/services/AccountApi';
import IbgeApi from '@/services/IbgeApi';
import ZipCodeApi from '@/services/ZipCodeApi';

export default {
  name: 'AppLinkPayment',

  components: {
    Datepicker,
    WhiteLabel,
  },

  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    linkPaymentEdit: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    index: 0,
    state: '',
    linkPayment: new LinkPayment(),
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      precision: 2,
      masked: false,
    },
    groups: {
      categoriesProduct: [],
    },
    citiesList: {
      cities: [{}],
    },
    listStates: [],
    listCities: [],
    isDisabledAddress: false,
    isDisabledAddressLine1: false,
    isDisabledDistrict: false,
    isDisabledCity: false,
    isDisabledState: false,
  }),

  computed: {
    emailIsInvalid() {
      return (
        this.$v.linkPayment.email.$invalid && this.$v.linkPayment.email.$dirty
      );
    },
    nameIsInvalid() {
      return (
        this.$v.linkPayment.fullName.$invalid &&
        this.$v.customer.fullName.$dirty
      );
    },
    documentIsInvalid() {
      return (
        this.$v.linkPayment.document.$invalid &&
        this.$v.linkPayment.document.$dirty
      );
    },
    allCities() {
      return this.listCities;
    },
    allStates() {
      return this.listStates;
    },
    allBanks() {
      return this.listBanks;
    },
  },

  async mounted() {
    await this.addlbillingDetailsAll();
    this.groups = await new PaymentsApi().categoriesProduct();
    this.citiesList = await new AccountApi().getCities();
    await this.searchStates();
  },

  methods: {
    compareStates(a, b) {
      if (a.sigla < b.sigla) {
        return -1;
      }
      if (a.sigla > b.sigla) {
        return 1;
      }
      return 0;
    },

    async searchStates() {
      const api = new IbgeApi();
      this.listStates = (await api.searchStates()).sort(this.compareStates);
    },

    async searchCities(initials) {
      if (initials !== '' && initials !== null && initials !== undefined) {
        this.listCities = [];
        const selectedState = this.listStates.filter(
          (state) => state.sigla === initials
        );

        const api = new IbgeApi();
        const result = await api.searchCities(selectedState[0].id);
        this.listCities = result;

        return;
      }
      this.listCities = [];
    },

    async searchZipCode(zipCode) {
      const zipCodeWithoutMask = zipCode.replace('-', '');

      if (zipCodeWithoutMask.length === 8) {
        const api = new ZipCodeApi();
        this.$bus.$emit('spinner-run');
        const result = await api.searchByZipCode(zipCodeWithoutMask);

        if (result.erro) {
          this.isDisabledAddressLine1 = true;
          this.isDisabledDistrict = true;
          this.isDisabledCity = true;
          this.isDisabledState = true;

          this.listCities = [];

          this.$bus.$emit('spinner-stop');

          this.$bus.$emit('alert-error', { title: 'CEP não encontrado!' });
          this.linkPayment.addressZipCode = '';

          return;
        }

        this.linkPayment.addressLine1 = result.logradouro;
        this.linkPayment.addressDistrict = result.bairro;
        this.state = result.uf;
        this.linkPayment.addressCityCode = result.ibge;
        this.linkPayment.addressCity = result.localidade;
        this.linkPayment.addressState = result.uf;

        await this.searchCities(this.state);

        this.isDisabledAddressLine1 = false;
        this.isDisabledDistrict = false;
        this.isDisabledCity = false;
        this.isDisabledState = false;
      }
      this.$bus.$emit('spinner-stop');
    },

    async addlbillingDetailsAll(index) {
      if (index > 0) {
        this.$delete(this.linkPayment.billingDetails, index);
        return;
      }
      this.linkPayment.billingDetails.push({
        sku: '',
        description: '',
        quantity: 1,
        price: 0,
        code: '',
      });
    },

    async save() {
      if (this.$v.$invalid) {
        this.$v.$touch();
      } else {
        var totalAmount = this.linkPayment.billingDetails.reduce(
          (total, x) => total + x.price * x.quantity,
          0
        );
        if (totalAmount >= 6) {
          var title;
          var message;
          this.$bus.$emit('spinner-run');
          const api = new BusinessApi();
          const result = await api.linkPayments.save(this.linkPayment);

          if (
            result === undefined ||
            result.status == 422 ||
            result.status == 400
          ) {
            this.$alert.error(
              'Ops',
              'Dados inválidos para criação de link de cobrança!'
            );
            this.$bus.$emit('spinner-stop');
          } else {
            title = 'Cadastro Realizado';
            message = `Link para o cliente ${this.linkPayment.fullName} foi cadastrado com sucesso.`;
            this.$alert.info(title, message);
            this.$router.push({ name: 'linkpayment' });
          }
        } else {
          this.$alert.error(
            'Ops',
            'Valor mínimo para o link de cobrança é de R$6,00!'
          );
        }
      }
    },
  },

  validations: {
    linkPayment: {
      fullName: {
        required,
      },
      document: {
        required,
        cpfCnpj,
      },
      email: {
        required,
        email,
      },
      addressCityCode: {
        required,
      },
      addressDistrict: {
        required,
      },
      addressCityCode: {
        required,
      },
      addressLine1: {
        required,
      },
      addressStreetNumber: {
        required,
      },
      addressZipCode: {
        required,
      },
      phoneNumber: {
        required,
        phone,
      },
      dueDate: {
        required,
        todayOrAfter: minValue(new Date().addDays(-1)),
      },
      discountCard: {
        between: between(0, 9999),
      },
      discountBankSlip: {
        between: between(0, 9999),
      },
      billingDetails: {
        $each: {
          sku: {required},
          code: {required},
          price: {required, minValue: minValue(6)},
          quantity: {required, minValue: minValue(1)},
        },
      },
    },
  },
};
</script>
