<template>
  <div class="container-fluid mt-5">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10 col-xl-8">
        <div class="card">
          <div class="d-flex">
            <h3 class="font-weight-light pull-left ml-3">Link de cobrança</h3>
            <router-link
              :to="{ name: 'linkpayment' }"
              class="ml-auto mt-1 pull-right mr-4"
            >
              <i class="icon-arrow-left-circle"></i> Voltar
            </router-link>
          </div>
          <hr />
          <div class="card-content">
            <div class="form-row mb-5">
              <div class="col-sm-12">
                <h4 class="font-weight-light blue">Informações do cliente</h4>
              </div>
              <div class="form-group col-8 mb-1">
                <label for="inputNome">Nome/Razão social</label>
                <p>{{ billing.fullName }}</p>
              </div>
              <div class="form-group col-4 mb-1">
                <label for="inputCPF">CPF/CNPJ</label>
                <p>{{ billing.document }}</p>
              </div>
              <div class="form-group col-8 mb-1">
                <label for="inputEmail">Email</label>
                <p>{{ billing.email }}</p>
              </div>
              <div class="form-group col-4 mb-1">
                <label for="inputPhoneNumber">Celular</label>
                <p>{{ billing.phoneNumber }}</p>
              </div>
            </div>
              <div v-if="billing.hasAddress" class="form-row">
                <div class="col-sm-12">
                  <h4 class="font-weight-light blue">Informações do endereço de cobrança do cliente</h4>
                </div>
                <div class="form-group col-8 mb-1">
                  <label for="inputNome">Rua/Avenida/Logradouro</label>
                  <p>{{ billing.addressLine1 }}</p>
                </div>
                <div class="form-group col-4 mb-1">
                  <label for="inputCPF">Bairro</label>
                  <p>{{ billing.addressDistrict }}</p>
                </div>
                <div class="form-group col-4">
                  <label for="inputCPF">Número</label>
                  <p>{{ billing.addressStreetNumber }}</p>
                </div>
                <div class="form-group col-4">
                  <label for="inputCPF">CEP</label>
                  <p>{{ billing.addressZipCode }}</p>
                </div>
                <div class="form-group col-4">
                  <label for="inputCPF">Cidade</label>
                  <p>{{ billing.addressCity }}/{{ billing.addressState }}</p>
                </div>
                <div v-if="billing.addressLine2" class="form-group col-8 mb-1">
                  <label for="inputNome">Complemento</label>
                  <p>{{ billing.addressLine2 }}</p>
                </div>
            </div>
            <div class="form-row mb-5">
              <div class="col-sm-12">
                <h4 class="font-weight-light blue">Informações do link</h4>
              </div>
              <div class="form-group col-8 mb-1">
                <label for="inputCanceledIn">Url</label>
                <p>
                  <a
                    :href="billing.url"
                    alt="Link de cobrança"
                    target="_blank"
                    >{{ billing.url }}</a
                  >
                </p>
              </div>
              <div class="form-group col-4 mb-1">
                <label for="inputCanceledIn">Status</label>
                <p>
                  <span
                    class="badge badge-pill"
                    :class="statusColor(billing.status)"
                    >{{ getStatus(billing.status) }}</span
                  >
                </p>
              </div>
              <div class="form-group col-4">
                <label for="inputDuaDate">Data do vencimento</label>
                <p>{{ billing.dueDate | moment("DD/MM/YYYY") }}</p>
              </div>
              <div v-if="billing.paidIn" class="form-group col-4">
                <label for="inputPaindIn">Pago em</label>
                <p>{{ billing.paidIn | moment("DD/MM/YYYY HH:mm:ss") }}</p>
              </div>
              <div v-if="billing.canceledIn" class="form-group col-4">
                <label for="inputCanceledIn">Cancelado em</label>
                <p>{{ billing.canceledIn | moment("DD/MM/YYYY HH:mm:ss") }}</p>
              </div>
            </div>

            <div class="mb-5">
              <div class="form-row">
                <div class="col-sm-12">
                  <h4 class="font-weight-light blue">
                    Configurações do pagamento
                  </h4>
                </div>
              </div>
              <div class="form-row mb-2">
                <div class="form-group col-4">
                  <label for="inputCanceledIn">Forma de pagamento</label>
                  <p>{{ typePayment(billing.payment) }}</p>
                </div>
                <div
                  class="form-group col-4"
                  v-if="billing.payment !== 'bankSlip'"
                >
                  <label for="inputCanceledIn">Parcelas fixas</label>
                  <p>{{ billing.fixedInstallments ? "Sim" : "Não" }}</p>
                </div>
                <div
                  class="form-group col-2"
                  v-if="billing.payment !== 'bankSlip'"
                >
                  <label for="inputCPF">Máximo de parcelas</label>
                  <p>{{ billing.maximumInstallments }}</p>
                </div>
              </div>
              <div class="form-row mb-1">
                <div
                  class="form-group col-4"
                  v-if="billing.payment !== 'bankSlip'"
                >
                  <label for="inputPaindIn">Desconto em Cartão</label>
                  <p>{{ billing.discountCard }}%</p>
                </div>
                <div class="form-group col-4" v-if="billing.payment !== 'card'">
                  <label for="inputCPF">Desconto em Boleto</label>
                  <p>{{ billing.discountBankSlip }}%</p>
                </div>
              </div>
            </div>
            <div class="form-row mb-5">
              <div class="col-12">
                <h4 class="font-weight-light blue">Produtos/serviços</h4>
              </div>
              <div class="col-12 col-md-12">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col" class="text-left">SKU</th>
                      <th scope="col" class="text-left">Descrição</th>
                      <th scope="col" class="text-right">Quantidade</th>
                      <th scope="col" class="text-right">Valor</th>
                      <th scope="col" class="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="paymentDetail of billing.billingDetails"
                      :key="paymentDetail.hashId"
                    >
                      <td class="text-left">{{ paymentDetail.sku }}</td>
                      <td class="text-left">{{ paymentDetail.description }}</td>
                      <td class="text-right">
                        <strong>{{ paymentDetail.quantity }}</strong>
                      </td>
                      <td class="text-right">
                        <strong>{{
                          paymentDetail.price
                            | currency("R$ ", 2, {
                              decimalSeparator: ",",
                              thousandsSeparator: ".",
                            })
                        }}</strong>
                      </td>
                      <td class="text-right">
                        <strong>{{
                          paymentDetail.amount
                            | currency("R$ ", 2, {
                              decimalSeparator: ",",
                              thousandsSeparator: ".",
                            })
                        }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="4" class="text-right">Total</td>
                      <td class="text-right">
                        <h3>
                          {{
                            billing.amount
                              | currency("R$ ", 2, {
                                decimalSeparator: ",",
                                thousandsSeparator: ".",
                              })
                          }}
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <h4 class="font-weight-light blue">Histórico do link</h4>
          </div>
          <div class="timeline">
            <div
              v-for="paymentEvent of billing.billingEvents"
              :key="paymentEvent.id"
              class="
                row
                no-gutters
                justify-content-end justify-content-md-around
                align-items-start
                timeline-nodes
              "
            >
              <div class="col-10 col-md-5 order-3 order-md-1 timeline-content">
                <h3 class="text-light">
                  {{ paymentEvent.created | moment("DD/MM/YYYY HH:mm:ss") }}
                </h3>
                <p>{{ paymentEvent.description }}</p>
              </div>
              <div
                class="
                  col-2 col-sm-1
                  px-md-3
                  order-2
                  timeline-image
                  text-md-center
                "
              >
                <i class="icon-directions"></i>
              </div>
              <div
                class="col-10 col-md-5 order-1 order-md-3 py-3 timeline-date"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LinkPayment, LinkPaymentParams } from '@/models/LinkPayment';
import BusinessApi from '@/services/BusinessApi';

export default {
  name: 'AppLinkPayment',
  data() {
    return {
      billing: new LinkPayment(),
    };
  },
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.findLinkPayment();
    this.$bus.$emit('spinner-stop');
  },
  methods: {
    async findLinkPayment() {
      const api = new BusinessApi();
      this.billing = await api.linkPayments.find(
        this.$route.params.linkPaymentId
      );
    },

    getStatus(status) {
      switch (status) {
      case 'Paid':
        return 'Pago';
      case 'Canceled':
        return 'Cancelado';
      case 'Contested':
        return 'Contestado';
      case 'Expired':
        return 'Expirado';
      case 'Pending':
        return 'Pendente';
      default:
        break;
      }
    },
    statusColor(status) {
      switch (status) {
      case 'Paid':
        return 'badge-success';
      case 'Pending':
        return 'badge-pending';
      case 'Canceled':
        return 'badge-danger';
      case 'Expired':
        return 'badge-pending';
        /* eslint-disable no-unreachable */
      default:
        return 'badge-secondary';
        break;
      }
    },
    typePayment(type) {
      switch (type) {
      case 'both':
        return 'Cartão e Boleto';
      case 'card':
        return 'Cartão de Crédito';
        /* eslint-disable no-unreachable */
      default:
        return 'Boleto Bancário';
        break;
      }
    },
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    linkPaymentEdit: {
      type: Object,
      default: null,
    },
  },
};
</script>
