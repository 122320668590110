<template>
  <div>
    <div class="header-box pt-5 pb-4 mb-2">
      <div class="row justify-content-center mx-0">
        <div class="col-12 col-w-full col-xl-10">
          <div class="col-sm-12 mb-4">
            <WhiteLabel>
              <button
                class="btn btn-orange btn-pill align-self px-4"
                :disabled="selectedCustomersList.length === 0"
                @click.prevent="payWithBankSlip()"
              >
                Emitir boleto
              </button>
            </WhiteLabel>
          </div>
          <form class="row mx-0" autocomplete="off">
            <div class="col-sm-12 col-lg-3 mb-3">
              <div class="form-group">
                <input
                  type="text"
                  v-model="customerFilter.fullName"
                  class="form-control"
                  name="fullName"
                  maxlength="120"
                  placeholder="Nome"
                />
              </div>
            </div>
            <div class="col-sm-12 col-lg-3 mb-3">
              <div>
                <input
                  type="email"
                  v-model="customerFilter.email"
                  class="form-control"
                  name="email"
                  maxlength="120"
                  placeholder="E-mail"
                />
              </div>
            </div>
            <div class="col-sm-12 col-lg-3 mb-3">
              <div>
                <input
                  type="text"
                  class="form-control"
                  required
                  id="taxDocument"
                  v-model="customerFilter.taxDocument"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  placeholder="CPF/CNPJ"
                  name="document"
                  maxlength="18"
                />
              </div>
            </div>
            <div class="d-flex col-sm-12 col-md-6 col-lg-3 mb-3">
              <WhiteLabel>
                <button
                  @click.prevent="filter"
                  class="btn btn-md btn-orange mr-2"
                  :disabled="$v.$invalid"
                >
                  Pesquisar
                </button>
              </WhiteLabel>
              <WhiteLabel>
                <button
                  type="button"
                  @click="resetFilters"
                  class="btn btn-md btn-outline-orange ml-2"
                >
                  Limpar filtro
                </button>
              </WhiteLabel>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-3 mb-4">
        <h5 class="mt-1 text-muted mb-4 mb-lg-0">
          {{ selectedCustomersList.length }}/{{ customersLimit }} clientes
          selecionados
        </h5>
      </div>
      <div class="row">
        <div
          class="col-12 col-w-full col-md-12 col-lg-6 col-xl-4"
          v-for="(customer, index) of customersAll"
          :key="index"
        >
          <label class="radio-selector w-100">
            <div class="card table-card">
              <div class="card-content">
                <div class="d-flex mb-3">
                  <div class="pull-left mr-auto">
                    <span
                      class="badge badge-pill"
                      :class="statusColor(customer.active)"
                      >{{ customer.active ? "ATIVO" : "INATIVO" }}</span
                    >
                  </div>
                  <div class="pull-right">
                    <i
                      class
                      :class="
                        customer.personType === 'F'
                          ? 'icon-user'
                          : 'icon-briefcase'
                      "
                    ></i>
                  </div>
                </div>
                <div class="d-flex mb-4">
                  <div class="text-center mx-auto">
                    <h5 class="font-weight-light mb-2">
                      {{ customer.fullName | abbreviateName(20) }}
                    </h5>
                    <p class="mb-3 text-muted">{{ customer.taxDocument }}</p>
                    <p class="mb-3 text-muted">{{ customer.email }}</p>
                    <p class="text-white" v-if="customer.email === null">•</p>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="ml-auto mb-1">
                    <input
                      type="checkbox"
                      v-model="selectedCustomersListDisplay[index]"
                      @click="clickCheckbox(index, customer)"
                    />
                    <div class="checkbox-badge"></div>
                  </div>
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div v-if="countCustomers === 0">
        <div class="text-center">
          <h4>Nenhum cliente encontrado.</h4>
          <h4 v-if="customersPermission">
            Mas você pode criar um novo cliente
            <WhiteLabel class="d-inline">
              <router-link :to="{ name: 'new-customer' }"
                >clicando aqui</router-link
              > </WhiteLabel
            >.
          </h4>
        </div>
      </div>
      <div class="row justify-content-center mx-0">
        <app-paginator
          :count="countCustomers"
          :perPage="perPage"
          @exchange="turnPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import paggcerto from 'paggcerto-lightbox/src/js';
import { mask } from 'vue-the-mask';
import { email } from 'vuelidate/lib/validators';
import { cpfCnpj } from '@/validations/cpfCnpj';
import BusinessApi from '@/services/BusinessApi';
import AppPaginator from '@/components/theme/Paginator';
import WhiteLabel from '@/components/shared/WhiteLabel';
import CustomerError from '@/errors/CustomerError';
import { PermissionList } from '@/models/Permission';
import { CustomerFilter } from '@/models/Customer';
import Alert from '@/models/Alert';

export default {
  name: 'AppSelectCustomer',
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.search();
    this.mountCheckboxGrid();
    if (this.whoAmI.user.role !== null) {
      await this.getScopes();
    }
    this.$bus.$emit('spinner-stop');
  },
  data() {
    return {
      customers: new CustomerFilter(),
      perPage: 12,
      customerFilter: new CustomerFilter(),
      count: 0,
      permissionList: new PermissionList(),
      selectedCustomersList: [],
      selectedCustomersListDisplay: [],
      customersLimit: 100,
      limitReached: false,
    };
  },
  directives: {
    mask,
  },
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
      token: 'account/token',
    }),
    customersPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'business.customers'
      );
    },
    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    customersPermission() {
      if (
        this.permissionList.permissions[this.customersPermissionIndex].grant ===
          'edit' ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
    customersAll() {
      return this.count === 0 ? [] : this.customers;
    },
    countCustomers() {
      return this.count;
    },
  },
  methods: {
    ...mapActions({
      askWhoAmI: 'account/askWhoAmI',
    }),
    async getScopes() {
      this.permissionList.fromScopes(this.whoAmI.user.role.scopes);
    },
    resetFilters() {
      this.customerFilter = new CustomerFilter();
    },
    async filter(event = undefined) {
      this.$bus.$emit('spinner-run');
      const api = new BusinessApi();
      const result = await api.customers.search(this.customerFilter);
      this.customers = result.customers.slice(0, this.perPage);
      this.count = result.count;
      this.selectedCustomersListDisplay = [];
      this.mountCheckboxGrid();
      if (this.selectedCustomersList.length !== 0) {
        for (var i = 0; i < this.customers.length; i++) {
          for (var j = 0; j < this.selectedCustomersList.length; j++) {
            if (this.customers[i].id === this.selectedCustomersList[j].id) {
              this.selectedCustomersListDisplay[i] = true;
            }
          }
        }
      }
      this.$bus.$emit('spinner-stop');
    },
    async turnPage(numberPage) {
      const fim = numberPage * this.perPage;
      const inicio = fim - this.perPage;
      this.customerFilter.offset.index = inicio;
      await this.search();
      this.selectedCustomersListDisplay = [];
      this.mountCheckboxGrid();
      if (this.selectedCustomersList.length !== 0) {
        for (var i = 0; i < this.customers.length; i++) {
          for (var j = 0; j < this.selectedCustomersList.length; j++) {
            if (this.customers[i].id === this.selectedCustomersList[j].id) {
              this.selectedCustomersListDisplay[i] = true;
            }
          }
        }
      }
    },
    async search() {
      this.$bus.$emit('spinner-run');
      this.$data.customerFilter.email = '';
      this.$data.customerFilter.fullName = '';
      this.$data.customerFilter.taxDocument = '';
      const api = new BusinessApi();
      const result = await api.customers.search(this.$data.customerFilter);
      this.customers = result.customers.slice(0, this.perPage);
      this.count = result.count;
      this.$bus.$emit('spinner-stop');
    },
    statusColor(type) {
      return {
        'badge-success': type,
        'badge-danger': !type,
      };
    },
    clickCheckbox(index, customer) {
      if (!this.selectedCustomersListDisplay[index]) {
        if (this.selectedCustomersList.length < this.customersLimit) {
          this.selectedCustomersList.push(customer);
        } else {
          var alert = new Alert();
          alert.title = 'Limite máximo de emissões simultâneas atingido';
          alert.message = `Você só pode emitir boletos para ${this.customersLimit} clientes simultâneamente`;
          this.$bus.$emit('alert-warning', alert);
          this.limitReached = true;
        }
      } else {
        this.selectAll = false;
        for (var i = 0; i < this.selectedCustomersList.length; i++) {
          if (this.selectedCustomersList[i].id === customer.id) {
            this.selectedCustomersList.splice(i, 1);
          }
        }
      }
    },
    mountCheckboxGrid() {
      for (var i = 0; i < this.perPage; i++) {
        this.selectedCustomersListDisplay.push(false);
      }
    },
    payWithBankSlip() {
      const result = [];
      for (var i = 0; i < this.selectedCustomersList.length; i++) {
        result.push({
          sellingKey: null,
          fullName: this.selectedCustomersList[i].fullName,
          taxDocument: this.selectedCustomersList[i].taxDocument,
          mobile: this.selectedCustomersList[i].mobile,
          email: this.selectedCustomersList[i].email,
        });
      }
      var _this = this;
      paggcerto.lightbox({
        environment: process.env.VUE_APP_LIGHTBOX,
        token: this.token,
        payment: {
          amount: null,
          replicateAmount: true,
          bankSlipEnabled: true,
          creditEnabled: false,
          debitEnabled: false,
          payers: result,
        },
        abort: function() {
          _this.selectedCustomersListDisplay = [];
          _this.selectedCustomersList = [];
          _this.mountCheckboxGrid();
        },
        success: function(paymentResult) {
          _this.selectedCustomersListDisplay = [];
          _this.selectedCustomersList = [];
          _this.mountCheckboxGrid();
        },
        fail: function(paymentResult) {},
      });
    },
  },
  watch: {
    limitReached: function() {
      if (this.limitReached) {
        this.selectedCustomersListDisplay = [];
        this.mountCheckboxGrid();
        for (var i = 0; i < this.customers.length; i++) {
          this.selectedCustomersListDisplay[i] = false;
          for (var j = 0; j < this.selectedCustomersList.length; j++) {
            if (this.customers[i].id === this.selectedCustomersList[j].id) {
              this.selectedCustomersListDisplay[i] = true;
            }
          }
        }
        this.limitReached = false;
      }
    },
  },
  validations: {
    customerFilter: {
      email: { email },
      taxDocument: { cpfCnpj },
    },
  },
  components: {
    AppPaginator,
    WhiteLabel,
  },
};
</script>
