export class UnitMeasure {
    id = 0
    symbol = ''
    name = ''
    canFraction = false
}

export class UnitMeasureFilter {
    nameOrSymbol = ''
}

export class UnitMeasureList {
    unitsMeasure = []
    count = 0
}
