<template>
  <div>
    <div class="header-box pt-5 pb-4 mb-5">
      <div class="row justify-content-center mx-0">
        <div class="col-12 col-w-full col-xl-10">
          <div class="col-sm-12 mb-4"></div>
          <form class="row mx-0" autocomplete="off">
            <div class="col-sm-12 col-lg-3 mb-3">
              <div class="form-group">
                <input
                  type="text"
                  v-model="sellerFilter.fullName"
                  class="form-control"
                  name="fullNames"
                  maxlength="120"
                  placeholder="Nome"
                />
              </div>
            </div>
            <div class="col-sm-12 col-lg-3 mb-3">
              <div>
                <input
                  type="text"
                  class="form-control"
                  required
                  id="email"
                  v-model="sellerFilter.email"
                  placeholder="E-mail"
                  maxlength="100"
                />
              </div>
            </div>
            <div class="col-sm-12 col-lg-3 mb-3">
              <div>
                <input
                  type="text"
                  class="form-control"
                  required
                  id="taxDocument"
                  v-model="sellerFilter.taxDocument"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  placeholder="CPF/CNPJ"
                  name="document"
                  maxlength="18"
                />
              </div>
            </div>
            <div class="d-flex col-sm-12 col-md-6 col-lg-3 mb-3">
              <WhiteLabel>
                <button
                  @click.prevent="filter"
                  class="btn btn-orange mr-2"
                  :disabled="$v.$invalid"
                >
                  Pesquisar
                </button>
              </WhiteLabel>
              <WhiteLabel>
                <button
                  type="button"
                  @click="resetFilters"
                  class="btn btn-md btn-outline-orange ml-2"
                >
                  Limpar filtro
                </button>
              </WhiteLabel>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div
          class="col-12 col-w-full col-md-12 col-lg-6 col-xl-4"
          v-for="seller in sellersList"
          :key="seller.id"
        >
          <div class="card table-card">
            <div class="card-content">
              <div class="d-flex mb-3">
                <div class="pull-right">
                  <i
                    class
                    :class="!seller.company ? 'icon-user' : 'icon-briefcase'"
                  ></i>
                </div>
                <div
                  v-if="seller.company"
                  class="text-center mx-auto text-truncate"
                >
                  <h5 class="font-weight-light mb-2">
                    {{ seller.company.tradeName | abbreviateName(20) }}
                  </h5>

                  <p class="mb-3 text-muted">
                    {{ seller.company.taxDocument }}
                  </p>
                  <p class="mb-3 text-muted">{{ seller.email }}</p>
                  <p class="text-white" v-if="seller.email === null">•</p>
                </div>
                <div
                  v-else-if="seller.forgetData"
                  class="text-center mx-auto text-truncate"
                >
                  <h5 class="font-weight-light mb-2">
                    {{ seller.commercialName }}
                  </h5>
                </div>
                <div v-else class="text-center mx-auto text-truncate">
                  <h5 class="font-weight-light mb-2">
                    {{ seller.fullName | abbreviateName(20) }}
                  </h5>
                  <p class="mb-3 text-muted">{{ seller.taxDocument }}</p>
                  <p class="mb-3 text-muted">{{ seller.email }}</p>
                  <p class="text-white" v-if="seller.email === null">•</p>
                </div>
              </div>
            </div>
            <div class="d-flex mb-1">
              <div class="text-center mx-auto">
                <div class="dropdown text-center mx-auto">
                  <WhiteLabel>
                    <button
                      type="button"
                      :class="`btn btn-outline-orange btn-small btn-pill${
                        seller.accountActive ? ' dropdown-toggle' : ''
                      }`"
                      :disabled="!seller.accountActive"
                      data-toggle="dropdown"
                    >
                      {{ seller.accountActive ? 'Ações' : 'Conta inativada' }}
                      <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <router-link
                          class="dropdown-item"
                          :to="{
                            name: 'change-seller-holder',
                            params: { holderId: seller.id },
                          }"
                        >
                          Alterar representante
                        </router-link>
                      </li>
                      <li>
                        <button
                          type="button"
                          class="dropdown-item"
                          @click="deactivateAccount(seller.id)"
                        >
                          Desativar conta
                        </button>
                      </li>
                    </ul>
                  </WhiteLabel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="sellersList.length === 0">
        <div class="text-center">
          <h4>Nenhum títular encontrado.</h4>
        </div>
      </div>
      <div class="row justify-content-center mx-0">
        <app-paginator
          :count="this.count"
          :perPage="perPage"
          @exchange="turnPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import { email } from 'vuelidate/lib/validators';
import { cpfCnpj } from '@/validations/cpfCnpj';
import AccountApi from '@/services/AccountApi';
import AppPaginator from '@/components/theme/Paginator';
import WhiteLabel from '@/components/shared/WhiteLabel';
import swal from 'sweetalert2';
import DeactivateAccountError from '@/errors/DeactivateAccountError';

export default {
  name: 'AppSearchSeller',

  components: {
    AppPaginator,
    WhiteLabel,
  },
  data: () => ({
    sellersList: [],
    perPage: 12,
    index: 0,
    sellerFilter: {
      fullName: '',
      taxDocument: '',
      email: '',
    },
    count: 0,
  }),

  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
    }),
  },

  async mounted() {
    await this.initialize();
  },

  directives: {
    mask,
  },

  methods: {
    ...mapActions({
      askWhoAmI: 'account/askWhoAmI',
    }),

    async initialize() {
      const api = new AccountApi();
      this.$bus.$emit('spinner-run');
      const sellersResult = await api.getSellers({
        length: this.perPage,
        index: this.index,
      });

      this.sellersList = sellersResult.data.sellers;
      this.count = sellersResult.data.count;
      this.$bus.$emit('spinner-stop');
      this.$bus.$emit('refresh-warnings');
    },

    deactivateAccount(holderId) {
      const api = new AccountApi();
      var forgetHolderData;
      swal({
        title: 'Realmente deseja desativar esta conta?',
        text: 'Esta ação não pode ser revertida!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, desativar',
        cancelButtonText: 'Não, cancelar',
        reverseButtons: true,
        allowOutsideClick: () => !swal.isLoading(),
      }).then(async (result) => {
        if (result.value) {
          swal({
            title:
              'Deseja esquecer os dados do representante legal desta conta?',
            html: '<p style="font-size:80%;">Essa opção dispõem sobre o tratamento dos dados pessoais do representante legal da empresa, objetivando proteger seus direitos fundamentais de liberdade e de privacidade e o livre desenvolvimento da personalidade da pessoa natural, em concordância com o art. 5º da Lei Geral de Proteção de Dados (LGPD - Lei Nº 13.709, de 14 de agosto de 2018)</p><input type="radio" id="forget-holder-data" name="forget-holder-data"> <label for="forget-holder-data">Esquecer os dados</label><br><input type="radio" id="dont-forget-holder-data" name="forget-holder-data" checked="checked" > <label for="dont-forget-holder-data">Não esquecer os dados</label>',
            showLoaderOnConfirm: true,
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            preConfirm: () => {
              forgetHolderData = $('#forget-holder-data').is(':checked');
            },
            allowOutsideClick: () => !swal.isLoading(),
          }).then(async (result) => {
            if (result.value) {
              this.$bus.$emit('spinner-run');
              var response = await api.deactivateSeller(
                holderId,
                forgetHolderData
              );
              this.$bus.$emit('spinner-stop');
              if (response.status == 200) {
                await this.initialize();
                this.$alert.info('Pronto', 'Conta desativada!');
              } else if (response.status == 422) {
                const title = 'Ops';
                const modelError = new DeactivateAccountError(response.data);
                const error = modelError.getMessage();
                this.$alert.error(title, `${error}`);
              } else {
                this.$alert.error('Ops', 'Ocorreu um erro inesperado');
              }
            }
          });
        }
      });
    },

    resetFilters() {
      this.sellerFilter = {
        fullName: '',
        taxDocument: '',
        email: '',
      };
      this.search();
    },

    async filter(event = undefined) {
      this.$bus.$emit('spinner-run');
      const api = new AccountApi();
      const result = await api.getSellers(this.sellerFilter);

      this.sellersList = result.data.sellers;
      this.count = result.data.count;
      this.$bus.$emit('spinner-stop');
    },

    async turnPage(numberPage) {
      const end = numberPage * this.perPage;
      const start = end - this.perPage;
      this.index = start;
      await this.search();
    },

    async search(length) {
      this.$bus.$emit('spinner-run');

      const api = new AccountApi();
      const result = await api.getSellers({
        length: this.perPage,
        index: this.index,
      });

      this.sellersList = result.data.sellers;
      this.count = result.data.count;

      this.$bus.$emit('spinner-stop');
    },

    statusColor(type) {
      return {
        'badge-success': type,
        'badge-danger': !type,
      };
    },
  },

  validations: {
    sellerFilter: {
      email: { email },
      taxDocument: { cpfCnpj },
    },
  },
};
</script>
