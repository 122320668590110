import { Offset } from '@/models/Offset';
export class Customer {
    id = ''
    sellerId = null
    userId = null
    representativeId = null
    businessActivityId = null
    fullName = ''
    tradeName = ''
    phone = null
    mobile = null
    addressLine1 = null
    addressLine2 = null
    addressNumber = null
    zipCode = null
    observation = ''
    gender = null
    active = null
    personType = null
    district = null
    state = null
    city = null
    taxDocument = null
    email = null
    birthDate = null
    createdAt = null
    representative = null
    emailNotificationPaused = false
    mobileNotificationPaused = false
}

export class CustomerFilter {
    fullName = ''
    email = ''
    taxDocument = ''
    offset = new Offset()
}

export class CustomerParams {
    id = 0
    representativeId = null
    businessActivityId = null
    fullName = ''
    tradeName = ''
    phone = null
    mobile = null
    addressLine1 = null
    addressLine2 = null
    addressNumber = null
    zipCode = null
    observation = ''
    gender = null
    Active = null
    personType = null
    district = null
    state = null
    city = null
    TaxDocument = null
    email = null
    BirthDate = null
    CreatedAt = null
    Representative = null
    EmailNotificationPaused = false
    MobileNotificationPaused = false

    constructor(Customer) {
      this.id = Customer.id !== '' ? Customer.id : '';
      this.businessActivityId = Customer.businessActivityId === null ? null : Customer.businessActivityId;
      this.fullName = Customer.fullName === '' ? '' : Customer.fullName;
      this.tradeName = Customer.tradeName === '' ? null : Customer.tradeName;
      this.phone = Customer.phone === '' ? null : Customer.phone;
      this.mobile = Customer.mobile === '' ? null : Customer.mobile;
      this.addressLine1 = Customer.addressLine1 === '' ? null : Customer.addressLine1;
      this.addressLine2 = Customer.addressLine2 === '' ? null : Customer.addressLine2;
      this.addressNumber = Customer.addressNumber === null ? null : Customer.addressNumber;
      this.zipCode = Customer.zipCode === '' ? null : Customer.zipCode;
      this.observation = Customer.observation === '' ? null : Customer.observation;
      this.gender = Customer.gender === '' ? null : Customer.gender;
      this.Active = Customer.Active === '' ? null : Customer.Active;
      this.personType = Customer.taxDocument.length > 14 ? 'J' : 'F';
      this.district = Customer.district === '' ? null : Customer.district;
      this.state = Customer.state === '' ? null : Customer.state;
      this.city = Customer.city === '' ? null : Customer.city;
      this.TaxDocument = Customer.taxDocument === '' ? null : Customer.taxDocument;
      this.email = Customer.email === '' ? null : Customer.email;
      this.BirthDate = Customer.birthDate === '' ? null : Customer.birthDate;
      this.CreatedAt = Customer.createdAt === '' ? null : Customer.createdAt;
      this.EmailNotificationPaused = Customer.emailNotificationPaused;
      this.MobileNotificationPaused = Customer.mobileNotificationPaused;
      this.Representative = Customer.representative === null ? null : (Customer.representative.name === '' && Customer.representative.taxDocument === '' && Customer.representative.gender === '') ? null : Customer.representative;
    }
}

export class CustomerList {
    customers = [];
    count = 0;
}
