import CustomApiError from './CustomError';

export default class ChangeSellerHolderError extends CustomApiError {
  toDictionary() {
    return {
      DUPLICATE_TAX_DOCUMENT: 'CPF já existente.',
      EMAIL_ALREADY_TAKEN: 'E-mail já existente.',
      HOLDER_NOT_FOUND: 'Lojista não encontrado.',
    };
  }
}
