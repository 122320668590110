<template>
  <div>
    <div class="form-row">
      <div class="col-12 col-w-full">
        <WhiteLabel>
          <h4 class="font-weight-light blue">{{ title }}</h4>
        </WhiteLabel>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-12 col-w-full col-lg-6 mb-4">
        <label for="inputZipCode">CEP</label>
        <input
          type="text"
          class="form-control"
          @change="searchZipCode(customer.zipCode)"
          v-mask="'#####-###'"
          v-model="customer.zipCode"
          placeholder="Digite aqui seu CEP"
        />
      </div>
    </div>
    <div class="form-row mb-4">
      <div class="form-group col-12 col-w-full col-lg-6 mb-4">
        <label for="inputAddressLine1">Avenida/Rua/Logradouro</label>
        <input
          type="text"
          maxlength="150"
          v-model="customer.addressLine1"
          class="form-control"
          placeholder="Digite aqui seu logradouro"
          :disabled="isDisabledAddressLine1"
          @input="$parent.$v.customer.addressLine1.$touch()"
        />
        <small class="text-danger" v-if="addressLine1IsInvalid"
          >O campo está inválido.</small
        >
      </div>
      <div class="form-group col-6 col-lg-2 mb-4">
        <label for="inputAddressNumber">Número</label>
        <input
          type="text"
          maxlength="10"
          v-model="customer.addressNumber"
          placeholder="Nº"
          @input="$parent.$v.customer.addressNumber.$touch()"
          class="form-control"
        />
        <small class="text-danger" v-if="addressNumberIsInvalid"
          >O campo está inválido.</small
        >
      </div>
      <div class="form-group col-6 col-lg-4 mb-4">
        <label for="inputAddressLine2">Complemento</label>
        <input
          type="text"
          v-model="customer.addressLine2"
          maxlength="80"
          placeholder="Apartamento, bloco..."
          class="form-control"
          @input="$parent.$v.customer.addressLine2.$touch()"
        />
        <small class="text-danger" v-if="addressLine2IsInvalid"
          >O campo está inválido.</small
        >
      </div>
      <div class="form-group col-12 col-w-full col-lg-6 mb-4">
        <label for="inputNeighborhood">Bairro</label>
        <input
          type="text"
          class="form-control"
          maxlength="50"
          placeholder="Digite aqui o seu bairro"
          v-model="customer.district"
          :disabled="isDisabledDistrict"
          @input="$parent.$v.customer.district.$touch()"
        />
        <small class="text-danger" v-if="districtIsInvalid"
          >O campo está inválido.</small
        >
      </div>
      <div class="form-group col-4 col-lg-2 mb-4">
        <label for="inputState">Estado(UF)</label>
        <select
          class="form-control text-center"
          name="state"
          @change="searchCities(customer.state)"
          v-model="customer.state"
          :disabled="isDisabledState"
        >
          <option value>Selecione</option>
          <option
            v-for="state in allStates"
            :key="state.id"
            :value="state.sigla"
          >
            {{ state.sigla }}
          </option>
        </select>
      </div>
      <div class="form-group col-8 col-lg-4">
        <label for="inputCity">Cidade</label>
        <select
          class="form-control"
          name="city"
          v-model="customer.city"
          :disabled="isDisabledCity"
        >
          <option value>Selecione</option>
          <option v-for="city in allCities" :key="city.id" :value="city.nome">
            {{ city.nome }}
          </option>
        </select>
      </div>
      <div class="form-group col-12 col-w-full">
        <label for="inputObservation">Observaçôes</label>
        <textarea
          class="form-control"
          v-model="customer.observation"
          placeholder="Digite aqui suas observações"
          maxlength="255"
          rows="3"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { mixins } from './mixins.js';
import ZipCodeApi from '@/services/ZipCodeApi';
import IbgeApi from '@/services/IbgeApi';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppAddressCustomer',
  components: {
    WhiteLabel,
  },
  async mounted() {
    await this.searchStates();
    if (this.customer.zipCode) {
      this.searchCities(this.customer.state);
      (this.isDisabledAddressLine1 = false);
      (this.isDisabledDistrict = false);
      (this.isDisabledCity = false);
      (this.isDisabledState = false);
    }
  },
  data() {
    return {
      listStates: [],
      listCities: [],
      isDisabledAddress: false,
      isDisabledAddressLine1: false,
      isDisabledDistrict: false,
      isDisabledCity: false,
      isDisabledState: false,
    };
  },
  props: {
    isJuridic: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    compareStates(a, b) {
      if (a.sigla < b.sigla) {
        return -1;
      }
      if (a.sigla > b.sigla) {
        return 1;
      }
      return 0;
    },
    async searchStates() {
      const api = new IbgeApi();
      this.listStates = (await api.searchStates()).sort(this.compareStates);
    },
    async searchCities(initials) {
      if (initials !== '' && initials !== null && initials !== undefined) {
        this.listCities = [];
        const selectedState = this.listStates.filter(
          (state) => state.sigla === initials
        );
        const api = new IbgeApi();
        const result = await api.searchCities(selectedState[0].id);
        this.listCities = result;
        return;
      }
      this.listCities = [];
    },
    async searchZipCode(zipCode) {
      const zipCodeWithoutMask = zipCode.replace('-', '');
      if (zipCodeWithoutMask.length === 8) {
        const api = new ZipCodeApi();
        this.$bus.$emit('spinner-run');
        const result = await api.searchByZipCode(zipCodeWithoutMask);
        if (result.erro) {
          this.customer.addressLine1 = null;
          this.customer.addressLine2 = null;
          this.customer.district = null;
          this.customer.state = '';
          this.customer.city = '';
          this.isDisabledAddressLine1 = false;
          this.isDisabledDistrict = false;
          this.isDisabledCity = false;
          this.isDisabledState = false;
          this.listCities = [];
          this.$bus.$emit('spinner-stop');
          return;
        }
        this.customer.addressLine1 = result.logradouro;
        this.customer.district = result.bairro;
        this.customer.state = result.uf;
        this.customer.city = result.localidade;
        this.isDisabledAddressLine1 = this.customer.addressLine1 !== '';
        this.isDisabledDistrict = this.customer.district !== '';
        this.isDisabledCity = this.customer.city !== '';
        this.isDisabledState = this.customer.state !== '';
        this.searchCities(this.customer.state);
      }
      this.$bus.$emit('spinner-stop');
    },
  },
  computed: {
    title() {
      var title = !this.isJuridic
        ? 'Endereço Residencial'
        : 'Endereço Comercial';
      return title;
    },
    allCities() {
      return this.listCities;
    },
    allStates() {
      return this.listStates;
    },
    addressNumberIsInvalid() {
      return (
        this.$parent.$v.customer.addressNumber.$invalid &&
        this.$parent.$v.customer.addressNumber.$dirty
      );
    },
    addressLine1IsInvalid() {
      return (
        this.$parent.$v.customer.addressLine1.$invalid &&
        this.$parent.$v.customer.addressLine1.$dirty
      );
    },
    addressLine2IsInvalid() {
      return (
        this.$parent.$v.customer.addressLine2.$invalid &&
        this.$parent.$v.customer.addressLine2.$dirty
      );
    },
    districtIsInvalid() {
      return (
        this.$parent.$v.customer.district.$invalid &&
        this.$parent.$v.customer.district.$dirty
      );
    },
  },
  mixins: [mixins],
};
</script>
