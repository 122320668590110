<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-w-full col-lg-10 col-xl-8">
        <div class="card mt-5">
          <div class="d-flex">
            <h3 class="font-weight-light pull-left ml-3">Pessoa Física</h3>
            <WhiteLabel class="ml-auto mt-1 pull-right mr-4">
              <router-link :to="{ name: 'customer' }">
                <i class="icon-arrow-left-circle"></i> Voltar
              </router-link>
            </WhiteLabel>
          </div>
          <hr />
          <form action="#" @submit.prevent="save()">
            <div class="card-content mb-5">
              <div class="form-row">
                <div class="col-sm-12">
                  <WhiteLabel>
                    <h4 class="font-weight-light blue">Dados do Cliente</h4>
                  </WhiteLabel>
                </div>
                <div class="form-group col-12 col-w-full col-lg-7 mb-4">
                  <label for="inputNome">Nome *</label>
                  <input
                    type="text"
                    class="form-control"
                    @input="$v.customer.fullName.$touch()"
                    v-model="customer.fullName"
                    maxlength="120"
                    placeholder="Digite aqui o nome do cliente"
                  />
                  <small
                    class="text-danger"
                    v-if="
                      !$v.customer.fullName.required &&
                      $v.customer.fullName.$dirty
                    "
                    >Campo obrigatório</small
                  >
                  <small
                    class="text-danger"
                    v-if="!$v.customer.fullName.propName"
                    >Permitido somente letras</small
                  >
                </div>
                <div class="form-group col-12 col-w-full col-lg-5 mb-4">
                  <label for="inputCPF">CPF *</label>
                  <input
                    type="text"
                    class="form-control"
                    @input="$v.customer.taxDocument.$touch()"
                    v-model="customer.taxDocument"
                    maxlength="18"
                    v-mask="'###.###.###-##'"
                    placeholder="Digite aqui o CPF do cliente"
                  />
                  <small
                    class="text-danger"
                    v-if="
                      !$v.customer.taxDocument.required &&
                      $v.customer.taxDocument.$dirty
                    "
                    >Campo obrigatório</small
                  >
                  <small class="text-danger" v-if="!$v.customer.taxDocument.cpf"
                    >O campo CPF está invalido</small
                  >
                </div>
                <div class="form-group col-6 col-lg-4 col-xl-4 mb-4">
                  <label>Gênero</label>
                  <select
                    class="form-control"
                    name="gender"
                    v-model="customer.gender"
                  >
                    <option value>Selecione</option>
                    <option value="F">Feminino</option>
                    <option value="M">Masculino</option>
                  </select>
                </div>
                <div class="form-group col-6 col-lg-3 col-xl-3 mb-4">
                  <label for="inputBirthDate">Data Nascimento</label>
                  <datepicker
                    v-model="customer.birthDate"
                    input-class="form-control text-center"
                    :calendar-button="false"
                    calendar-button-icon="icon-calendar"
                    language="pt-br"
                    format="dd/MM/yyyy"
                    placeholder="00/00/0000"
                  />
                </div>
              </div>
            </div>
            <div class="card-content mb-5">
              <contact-information
                :customer="customer"
                @inputEmail="changeEmail"
                @inputSms="changeSms"
              />
            </div>
            <div class="card-content mb-5">
              <address-customer :customer="customer" />
            </div>
            <div class="d-flex">
              <WhiteLabel class="mb-4 mx-auto">
                <button type="submit" class="btn btn-md btn-orange">
                  {{ isEdit ? "Atualizar" : "Cadastrar" }} Cliente
                </button>
              </WhiteLabel>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddressCustomer from './AddressCustomer';
import ContactInformation from './ContactInformation';
import { Customer, CustomerParams } from '@/models/Customer';
import { required, email } from 'vuelidate/lib/validators';
import { cpf } from '@/validations/cpf';
import { propName } from '@/validations/propName';
import { alphaNumeric } from '@/validations/alphaNumeric';
import { address } from '@/validations/address';
import BusinessApi from '@/services/BusinessApi';
import CustomerError from '@/errors/CustomerError';
import Datepicker from 'vuejs-datepicker';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppPhysicalCustomer',
  data() {
    return {
      customer: this.customerEdit === null ? new Customer() : this.customerEdit,
      pausedEmail: null,
      pausedSms: null,
    };
  },
  mounted() {
    if (this.customerEdit === null) {
      this.customer.gender = '';
      this.customer.state = '';
      this.customer.city = '';
    }
  },
  components: {
    AddressCustomer,
    ContactInformation,
    Datepicker,
    WhiteLabel,
  },
  methods: {
    async changeEmail(email) {
      this.pausedEmail = email;
    },
    async changeSms(sms) {
      this.pausedSms = sms;
    },
    async save() {
      var title;
      var message;
      if (this.$v.$invalid) {
        this.$v.$touch();
      } else {
        const api = new BusinessApi();
        const customerParams = new CustomerParams({ ...this.customer });
        this.$bus.$emit('spinner-run');
        if (this.$route.params.customerId) {
          var result = await api.customers.update(customerParams);
          if (
            this.pausedEmail != null &&
            this.pausedEmail != this.customer.emailNotificationPaused
          ) {
            result = await api.customers.pauseEmail(
              this.$route.params.customerId
            );
          }
          if (
            this.pausedSms != null &&
            this.pausedSms != this.customer.mobileNotificationPaused
          ) {
            result = await api.customers.pauseSms(
              this.$route.params.customerId
            );
          }
          if (result.error) {
            const modelError = new CustomerError(result);
            this.$alert.error('Ops', modelError.getMessage());
            this.$bus.$emit('spinner-stop');
          } else {
            title = 'Cadastro Atualizado';
            message = `O cliente ${this.customer.fullName} foi atualizado com sucesso.`;
            this.$alert.info(title, message);
            this.$router.push({ name: 'customer' });
          }
        } else {
          var result = await api.customers.save(customerParams);
          if (result.error) {
            const modelError = new CustomerError(result);
            this.$alert.error('Ops', modelError.getMessage());
            this.$bus.$emit('spinner-stop');
          } else {
            title = 'Cadastro Realizado';
            message = `O cliente ${this.customer.fullName} foi cadastrado com sucesso.`;
            this.$alert.info(title, message);
            this.$router.push({ name: 'customer' });
            if (
              this.pausedEmail != null &&
              this.pausedEmail != this.customer.emailNotificationPaused
            ) {
              await api.customers.pauseEmail(result.id);
            }
            if (
              this.pausedSms != null &&
              this.pausedSms != this.customer.mobileNotificationPaused
            ) {
              await api.customers.pauseSms(result.id);
            }
          }
        }
      }
    },
    back() {
      if (this.isEdit) this.$router.push({ name: 'new-customer' });
      location.reload();
    },
  },
  validations: {
    customer: {
      fullName: {
        required,
        propName,
      },
      taxDocument: {
        cpf,
        required,
      },
      email: {
        email,
      },
      addressLine1: {
        address,
      },
      addressLine2: {
        alphaNumeric,
      },
      district: {
        alphaNumeric,
      },
      addressNumber: {
        alphaNumeric,
      },
    },
  },
  computed: {
    nameIsInvalid() {
      return (
        this.$v.customer.fullName.$invalid && this.$v.customer.fullName.$dirty
      );
    },
    taxDocumentIsInvalid() {
      return (
        this.$v.customer.taxDocument.$invalid &&
        this.$v.customer.taxDocument.$dirty
      );
    },
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    customerEdit: {
      type: Object,
      default: null,
    },
  },
};
</script>
