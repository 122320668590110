<template>
  <div>
    <div class="form-row">
      <div class="col-12 col-w-full">
        <WhiteLabel>
          <h4 class="font-weight-light blue">{{ title }}</h4>
        </WhiteLabel>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-12 col-w-full col-lg-6 mb-4">
        <label for="inputZipCode">CEP</label>
        <p>{{ customer.zipCode }}</p>
      </div>
    </div>
    <div class="form-row mb-4">
      <div class="form-group col-12 col-w-full col-lg-6 mb-4">
        <label for="inputAddressLine1">Avenida/Rua/Logradouro</label>
        <p>{{ customer.addressLine1 }}</p>
      </div>
      <div class="form-group col-6 col-lg-2 mb-4">
        <label for="inputAddressNumber">Número</label>
        <p>{{ customer.addressNumber }}</p>
      </div>
      <div class="form-group col-6 col-lg-4 mb-4">
        <label for="inputAddressLine2">Complemento</label>
        <p>{{ customer.addressLine2 }}</p>
      </div>
      <div class="form-group col-12 col-w-full col-lg-6 mb-4">
        <label for="inputNeighborhood">Bairro</label>
        <p>{{ customer.district }}</p>
      </div>
      <div class="form-group col-4 col-lg-2 mb-4">
        <label for="inputState">Estado(UF)</label>
        <p>{{ customer.state }}</p>
      </div>
      <div class="form-group col-8 col-lg-4">
        <label for="inputCity">Cidade</label>
        <p>{{ customer.city }}</p>
      </div>
      <div class="form-group col-12 col-w-full">
        <label for="inputObservation">Observaçôes</label>
        <p>{{ customer.observation }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mixins } from './mixins.js';
import ZipCodeApi from '@/services/ZipCodeApi';
import IbgeApi from '@/services/IbgeApi';
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppAddressCustomer',
  components: {
    WhiteLabel,
  },
  props: {
    isJuridic: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      var title = !this.isJuridic
        ? 'Endereço Residencial'
        : 'Endereço Comercial';
      return title;
    },
  },
  mixins: [mixins],
};
</script>
