<template>
  <div>
    <div class="header-box pt-5 pb-4 mb-5">
      <div class="row justify-content-center mx-0">
        <div class="col-12 col-xl-10">
          <form class="container" autocomplete="off">
            <div class="row">
              <div
                class="col-sm-12 mb-4"
                v-if="
                  !isEmployee
                "
              >
                <WhiteLabel>
                  <router-link
                    :to="{ name: 'new-linkpayment' }"
                    class="btn btn-orange btn-pill align-self"
                  >
                    <i class="icon-plus"></i> Novo link
                  </router-link>
                </WhiteLabel>
              </div>
              <div class="col mb-3">
                <div class="form-group">
                  <input
                    type="text"
                    v-model="linkPaymentFilter.fullName"
                    class="form-control"
                    name="fullName"
                    maxlength="120"
                    placeholder="Nome"
                  />
                </div>
              </div>
              <div class="col mb-3">
                <select class="form-control" v-model="linkPaymentFilter.status">
                  <option :value="[]">Status</option>
                  <option :value="['Paid']">Pago</option>
                  <option :value="['Pending']">Pendente</option>
                  <option :value="['Canceled']">Cancelado</option>
                  <option :value="['Expired']">Expirado</option>
                </select>
              </div>
              <div class="d-flex col-sm-12 col-md-6 col-lg-3 mb-3">
                <WhiteLabel>
                  <button
                    @click.prevent="filter"
                    class="btn btn-md btn-orange mr-2"
                    :disabled="$v.$invalid"
                  >
                    Pesquisar
                  </button>
                  <button
                    type="button"
                    @click="resetFilters"
                    class="btn btn-md btn-outline-orange ml-2"
                  >
                    Limpar filtro
                  </button>
                </WhiteLabel>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-12">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col" class="text-left">Destinatário</th>
                <th scope="col" class="text-left">Vencimento</th>
                <th scope="col" class="text-right">Valor</th>
                <th scope="col">Status</th>
                <th scope="col" class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="payment of paymentsAll" :key="payment.id">
                <td class="text-left">{{ payment.fullName }}</td>
                <td class="text-left">
                  {{ payment.dueDate | moment("DD/MM/YYYY") }}
                </td>
                <td class="text-right">
                  <strong>{{
                    payment.amount
                      | currency("R$ ", 2, {
                        decimalSeparator: ",",
                        thousandsSeparator: ".",
                      })
                  }}</strong>
                </td>
                <td class="text-right">
                  <span
                    v-if="payment.status === 'Paid'"
                    class="badge badge-pill badge-success w-75 p-2 pl-2 pr-2"
                    >Pago</span
                  >
                  <span
                    v-if="payment.status === 'Pending'"
                    class="badge badge-pill badge-pending w-75 p-2 pl-2 pr-2"
                    >Pendente</span
                  >
                  <span
                    v-if="payment.status === 'Canceled'"
                    class="badge badge-pill badge-secondary w-75 p-2 pl-2 pr-2"
                    >Cancelado</span
                  >
                  <span
                    v-if="payment.status === 'Expired'"
                    class="badge badge-pill badge-secondary w-75 p-2 pl-2 pr-2"
                    >Expirado</span
                  >
                </td>
                <td>
                  <div class="dropdown text-center mx-auto">
                    <WhiteLabel>
                      <button
                        type="button"
                        class="btn btn-outline-orange btn-pill dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Ações
                        <span class="caret"></span>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <router-link
                            v-if="payment.id != ''"
                            class="dropdown-item"
                            :to="{
                              name: 'linkpayment-visualizar',
                              params: { linkPaymentId: payment.id },
                            }"
                            >Visualizar</router-link
                          >
                        </li>
                        <li v-if="payment.status == 'Pending'">
                          <a
                            href="javascript:;"
                            class="dropdown-item"
                            @click.prevent="cancelPayment(payment.id)"
                            >Cancelar</a
                          >
                        </li>
                      </ul>
                    </WhiteLabel>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="countPayments === 0">
        <div class="text-center">
          <h4>Nenhum link encontrado.</h4>
        </div>
      </div>
      <div class="row justify-content-center mx-0">
        <app-paginator
          :count="countPayments"
          :perPage="perPage"
          @exchange="turnPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import { mapActions, mapGetters } from 'vuex';
import { email } from 'vuelidate/lib/validators';
import { cpfCnpj } from '@/validations/cpfCnpj';
import BusinessApi from '@/services/BusinessApi';
import AppPaginator from '@/components/theme/Paginator';
import WhiteLabel from '@/components/shared/WhiteLabel';
import { PermissionList } from '@/models/Permission';
import { LinkPaymentFilter } from '@/models/LinkPayment';

export default {
  name: 'AppSearchLinkPayment',
  async mounted() {
    this.$bus.$emit('spinner-run');
    await this.search();
    this.$bus.$emit('spinner-stop');
    this.$bus.$emit('refresh-warnings');
  },
  data() {
    return {
      linkPayments: new LinkPaymentFilter(),
      perPage: 30,
      linkPaymentFilter: new LinkPaymentFilter(),
      count: 0,
      permissionList: new PermissionList(),
    };
  },
  directives: {
    mask,
  },
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
    }),
    paymentsAll() {
      return this.count === 0 ? [] : this.linkPayments;
    },
    countPayments() {
      return this.count;
    },
    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    isAccountPartner() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByPartner;
    },
    isOriginPartner() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.isOriginPartner;
    },
    isEmployee() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByEmployee;
    },
  },
  methods: {
    async search() {
      this.$bus.$emit('spinner-run');
      this.$data.linkPaymentFilter.email = '';
      this.$data.linkPaymentFilter.fullName = '';
      this.$data.linkPaymentFilter.document = '';
      this.$data.linkPaymentFilter.status = [];
      const api = new BusinessApi();
      const result = await api.linkPayments.search(
        this.$data.linkPaymentFilter
      );
      if (result.error) {
        this.$alert.error('Ops', result.title);
      } else {
        this.linkPayments = result.billings.slice(0, this.perPage);
        this.count = result.count;
      }
      this.$bus.$emit('spinner-stop');
    },
    async filter(event = undefined) {
      this.$bus.$emit('spinner-run');
      const api = new BusinessApi();
      const result = await api.linkPayments.search(this.linkPaymentFilter);
      this.linkPayments = result.billings.slice(0, this.perPage);
      this.count = result.count;
      this.$bus.$emit('spinner-stop');
    },
    async cancelPayment(hashId) {
      this.$alert.question(
        'Cancelar link de cobrança',
        'Deseja realmente cancelar o link de cobrança?'
      );
      this.$alert.onConfirm = async() => {
        this.$bus.$emit('spinner-run');
        const api = new BusinessApi();
        api.linkPayments.cancel(hashId).then(() => {
          this.$alert.info(
            'Link de cobrança cancelado',
            `O link ${hashId} foi cancelado com sucesso.`
          );
          this.$bus.$emit('spinner-stop');
          this.search();
        });
      };
    },
    async turnPage(numberPage) {
      const fim = numberPage * this.perPage;
      const inicio = fim - this.perPage;
      this.$data.linkPaymentFilter.index = inicio;
      await this.search();
    },
    resetFilters() {
      this.linkPaymentFilter = new LinkPaymentFilter();
    },
    statusColor(type) {
      return {
        'badge-success': type,
        'badge-danger': !type,
      };
    },
  },
  validations: {
    linkPaymentFilter: {
      email: { email },
      document: { cpfCnpj },
    },
  },
  components: {
    AppPaginator,
    WhiteLabel,
  },
};
</script>
